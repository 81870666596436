.node-type-news-release{
  .content-area{
    /* max-width: 700px; */
    h1.page-header{
      font-size: 38px;
      line-height: 46px;
    }
    .field-name-field-publication-date{
      font-family: rsa_sansmedium;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #E8E6DC;
    }
    .field-name-field-preamble{
      font-family: rsa_sansregular;
      color: #666;
      font-size: 28px;
      line-height: 43px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .field-name-body{
      font-size: 16px;
      line-height: 28px;
      font-family: rsa_sansregular;
      color: #2E2E2E;
      padding-bottom: 20px;
      border-bottom: 1px solid #E8E6DC;
      margin-bottom: 20px;
      
      .rsa-news-quote{
        border-top: 6px solid #FF49A4;
        margin-left: 5%;
        margin-top: 30px;
        padding: 30px 10px 20px 40px;
        & > p{
          font-family: "RSA Sans Light",Arial,Helvetica,sans-serif;
          font-size: 28px;
          line-height: 43px;
          padding-bottom: 20px;
        }
        .rsa-news-quote-author{
          text-align: left;
          font-family: "RSA Sans Medium",Arial,Helvetica,sans-serif;
        }
        .rsa-news-quote-author-title{
          text-align: left;
        }
      }
    }
    .field-name-field-media-enquiries{
      margin-top: 20px;
      .field-label{
        font-size: 28px;
        line-height: 43px;
        color: black;
      }
      & > .field-items > .field-item{
        display: inline-block;
        width: 50%;
      }
      .field-item{
        font-size: 16px;
        line-height: 28px;
        color: #2e2e2e;
        .field-collection-view{
          border-bottom: none;
        }
      }
      .field-name-field-contact-name{
        font-family: "RSA Sans Medium",Arial,Helvetica,sans-serif;
      }
    }
  }
}

.view-news-and-press-releases{
  margin-top: 40px;
  .views-row{
    border-top: 1px solid #E8E6DC;
    &.views-row-last{
      border-bottom: 1px solid #E8E6DC;
    }
    .views-field{
      display: inline-block;
      font-size: 16px;
      line-height: 60px;
      a{
        text-decoration: none;
        &:hover{
          color: #7b5799;
        }
      }
      &.views-field-field-publication-date{
        width: 100px;
        margin-right: 30px;
      }
    }
  }
}