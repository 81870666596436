@font-face {
  font-family: 'rsa-icons';
  src: url('../fonts/rsa-icons.eot?97951932');
  src: url('../fonts/rsa-icons.eot?97951932#iefix') format('embedded-opentype'),
       url('../fonts/rsa-icons.woff?97951932') format('woff'),
       url('../fonts/rsa-icons.ttf?97951932') format('truetype'),
       url('../fonts/rsa-icons.svg?97951932#rsa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'chat-rsa';
  src:  url('../fonts/new-icons/chat-icon.eot');
  src:  url('../fonts/new-icons/chat-icon.eot') format('embedded-opentype'),
		url('../fonts/new-icons/chat-icon.ttf') format('truetype'),
		url('../fonts/new-icons/chat-icon.woff') format('woff'),
		url('../fonts/new-icons/chat-icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'rsa-icons';
    src: url('../font/rsa-icons.svg?97951932#rsa-icons') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-volume-low:before { content: '\e800'; } /* '' */
.icon-volume-off:before { content: '\e801'; } /* '' */
.icon-ship:before { content: '\e802'; } /* '' */
.icon-mic:before { content: '\e803'; } /* '' */
.icon-truck:before { content: '\e804'; } /* '' */
.icon-calendar:before { content: '\e805'; } /* '' */
.icon-basket:before { content: '\e806'; } /* '' */
.icon-turbine:before { content: '\e807'; } /* '' */
.icon-wrench:before { content: '\e808'; } /* '' */
.icon-umbrella:before { content: '\e809'; } /* '' */
.icon-cog:before { content: '\e80a'; } /* '' */
.icon-compass:before { content: '\e80b'; } /* '' */
.icon-menu:before { content: '\e80c'; } /* '' */
.icon-wheat:before { content: '\e80d'; } /* '' */
.icon-map:before { content: '\e80e'; } /* '' */
.icon-doc-text:before { content: '\e80f'; } /* '' */
.icon-doc-add:before { content: '\e810'; } /* '' */
.icon-doc-remove:before { content: '\e811'; } /* '' */
.icon-news:before { content: '\e812'; } /* '' */
.icon-folder:before { content: '\e813'; } /* '' */
.icon-folder-add:before { content: '\e814'; } /* '' */
.icon-folder-delete:before { content: '\e815'; } /* '' */
.icon-archive:before { content: '\e816'; } /* '' */
.icon-box:before { content: '\e817'; } /* '' */
.icon-rss:before { content: '\e818'; } /* '' */
.icon-woman:before { content: '\e819'; } /* '' */
.icon-phone:before { content: '\e81a'; } /* '' */
.icon-location:before { content: '\e81b'; } /* '' */
.icon-address:before { content: '\e81c'; } /* '' */
.icon-vcard:before { content: '\e81d'; } /* '' */
.icon-chat:before { content: '\e81e'; } /* '' */
.icon-comment:before { content: '\e81f'; } /* '' */
.icon-print:before { content: '\e820'; } /* '' */
.icon-edit:before { content: '\e821'; } /* '' */
.icon-feather:before { content: '\e822'; } /* '' */
.icon-pen:before { content: '\e823'; } /* '' */
.icon-pencil:before { content: '\e824'; } /* '' */
.icon-export:before { content: '\e825'; } /* '' */
.icon-eye:before { content: '\e826'; } /* '' */
.icon-tag:before { content: '\e827'; } /* '' */
.icon-tags:before { content: '\e828'; } /* '' */
.icon-bookmark:before { content: '\e829'; } /* '' */
.icon-flag:before { content: '\e82a'; } /* '' */
.icon-thumbs-up:before { content: '\e82b'; } /* '' */
.icon-thumbs-down:before { content: '\e82c'; } /* '' */
.icon-code:before { content: '\e82d'; } /* '' */
.icon-pin:before { content: '\e82e'; } /* '' */
.icon-lock-open:before { content: '\e82f'; } /* '' */
.icon-lock:before { content: '\e830'; } /* '' */
.icon-search:before { content: '\e831'; } /* '' */
.icon-mail:before { content: '\e832'; } /* '' */
.icon-heart:before { content: '\e833'; } /* '' */
.icon-star:before { content: '\e834'; } /* '' */
.icon-user:before { content: '\e835'; } /* '' */
.icon-users:before { content: '\e836'; } /* '' */
.icon-camera:before { content: '\e837'; } /* '' */
.icon-th:before { content: '\e838'; } /* '' */
.icon-th-large:before { content: '\e839'; } /* '' */
.icon-th-list:before { content: '\e83a'; } /* '' */
.icon-home:before { content: '\e83b'; } /* '' */
.icon-link:before { content: '\e83c'; } /* '' */
.icon-attach:before { content: '\e83d'; } /* '' */
.icon-picture:before { content: '\e83e'; } /* '' */
.icon-minus:before { content: '\e83f'; } /* '' */
.icon-user-add:before { content: '\e840'; } /* '' */
.icon-user-delete:before { content: '\e841'; } /* '' */
.icon-video:before { content: '\e842'; } /* '' */
.icon-videocam:before { content: '\e843'; } /* '' */
.icon-cancel-alt:before { content: '\e844'; } /* '' */
.icon-cancel-circled:before { content: '\e845'; } /* '' */
.icon-plus:before { content: '\e846'; } /* '' */
.icon-cat:before { content: '\e847'; } /* '' */
.icon-facebook:before { content: '\e848'; } /* '' */
.icon-flickr-circled:before { content: '\e849'; } /* '' */
.icon-flickr:before { content: '\e84a'; } /* '' */
.icon-github-circled:before { content: '\e84b'; } /* '' */
.icon-github:before { content: '\e84c'; } /* '' */
.icon-anchor:before { content: '\e84d'; } /* '' */
.icon-scissors:before { content: '\e84e'; } /* '' */
.icon-balloon:before { content: '\e84f'; } /* '' */
.icon-certificate:before { content: '\e850'; } /* '' */
.icon-disabled:before { content: '\e851'; } /* '' */
.icon-flow-split:before { content: '\e852'; } /* '' */
.icon-flow-merge:before { content: '\e853'; } /* '' */
.icon-flow-parallel:before { content: '\e854'; } /* '' */
.icon-flow-cross:before { content: '\e855'; } /* '' */
.icon-drizzle:before { content: '\e856'; } /* '' */
.icon-cloud-sun:before { content: '\e857'; } /* '' */
.icon-rain:before { content: '\e858'; } /* '' */
.icon-waves:before { content: '\e859'; } /* '' */
.icon-wrench-alt:before { content: '\e85a'; } /* '' */
.icon-wifi:before { content: '\e85b'; } /* '' */
.icon-target:before { content: '\e85c'; } /* '' */
.icon-plug:before { content: '\e85d'; } /* '' */
.icon-record-alt:before { content: '\e85e'; } /* '' */
.icon-bat2:before { content: '\e85f'; } /* '' */
.icon-bat3:before { content: '\e860'; } /* '' */
.icon-bat4:before { content: '\e861'; } /* '' */
.icon-bat-charge:before { content: '\e862'; } /* '' */
.icon-play:before { content: '\e863'; } /* '' */
.icon-padlock:before { content: '\e864'; } /* '' */
.icon-resize-full:before { content: '\e865'; } /* '' */
.icon-resize-normal:before { content: '\e866'; } /* '' */
.icon-wristwatch:before { content: '\e867'; } /* '' */
.icon-clock:before { content: '\e868'; } /* '' */
.icon-headphones:before { content: '\e869'; } /* '' */
.icon-volume-high:before { content: '\e86a'; } /* '' */
.icon-volume-middle:before { content: '\e86b'; } /* '' */
.icon-direction:before { content: '\e86c'; } /* '' */
.icon-trash:before { content: '\e86d'; } /* '' */
.icon-doc:before { content: '\e86e'; } /* '' */
.icon-contacts:before { content: '\e86f'; } /* '' */
.icon-warning-empty:before { content: '\e870'; } /* '' */
.icon-attention:before { content: '\e871'; } /* '' */
.icon-bell:before { content: '\e872'; } /* '' */
.icon-chat-alt:before { content: '\e873'; } /* '' */
.icon-download:before { content: '\e874'; } /* '' */
.icon-upload:before { content: '\e875'; } /* '' */
.icon-upload-cloud:before { content: '\e876'; } /* '' */
.icon-reply:before { content: '\e877'; } /* '' */
.icon-forward:before { content: '\e878'; } /* '' */
.icon-ok:before { content: '\e879'; } /* '' */
.icon-cancel:before { content: '\e87a'; } /* '' */
.icon-divide:before { content: '\e87b'; } /* '' */
.icon-eq:before { content: '\e87c'; } /* '' */
.icon-info:before { content: '\e87d'; } /* '' */
.icon-music:before { content: '\e87e'; } /* '' */
.icon-champagne:before { content: '\e87f'; } /* '' */
.icon-vimeo-circled:before { content: '\e880'; } /* '' */
.icon-car:before { content: '\e881'; } /* '' */
.icon-twitter-circled:before { content: '\e882'; } /* '' */
.icon-tumbler:before { content: '\e883'; } /* '' */
.icon-tumbler-circled:before { content: '\e884'; } /* '' */
.icon-bridge:before { content: '\e885'; } /* '' */
.icon-skype:before { content: '\e886'; } /* '' */
.icon-looped-square:before { content: '\e887'; } /* '' */
.icon-sort-alphabet:before { content: '\e888'; } /* '' */
.icon-sort-numeric:before { content: '\e889'; } /* '' */
.icon-dribbble-circled:before { content: '\e88a'; } /* '' */
.icon-dribbble:before { content: '\e88b'; } /* '' */
.icon-facebook-circled:before { content: '\e88c'; } /* '' */
.icon-lastfm-circled:before { content: '\e88d'; } /* '' */
.icon-lastfm:before { content: '\e88e'; } /* '' */
.icon-linkedin-circled:before { content: '\e88f'; } /* '' */
.icon-linkedin:before { content: '\e890'; } /* '' */
.icon-pinterest-circled:before { content: '\e891'; } /* '' */
.icon-pinterest:before { content: '\e892'; } /* '' */
.icon-at-circled:before { content: '\e893'; } /* '' */
.icon-at:before { content: '\e894'; } /* '' */
.icon-pi:before { content: '\e895'; } /* '' */
.icon-infinity:before { content: '\e896'; } /* '' */
.icon-calculator:before { content: '\e897'; } /* '' */
.icon-tree:before { content: '\e898'; } /* '' */
.icon-boat:before { content: '\e899'; } /* '' */
.icon-puzzle:before { content: '\e89a'; } /* '' */
.icon-hazard:before { content: '\e89b'; } /* '' */
.icon-gift:before { content: '\e89c'; } /* '' */
.icon-temperatire:before { content: '\e89d'; } /* '' */
.icon-chart:before { content: '\e89e'; } /* '' */
.icon-chart-alt:before { content: '\e89f'; } /* '' */
.icon-city:before { content: '\e8a0'; } /* '' */
.icon-chart-bar:before { content: '\e8a1'; } /* '' */
.icon-coat-hanger:before { content: '\e8a2'; } /* '' */
.icon-chart-pie:before { content: '\e8a3'; } /* '' */
.icon-coffee:before { content: '\e8a4'; } /* '' */
.icon-ticket:before { content: '\e8a5'; } /* '' */
.icon-credit-card:before { content: '\e8a6'; } /* '' */
.icon-clipboard:before { content: '\e8a7'; } /* '' */
.icon-database:before { content: '\e8a8'; } /* '' */
.icon-key:before { content: '\e8a9'; } /* '' */
.icon-check:before { content: '\e8aa'; } /* '' */
.icon-check-alt:before { content: '\e8ab'; } /* '' */
.icon-dog:before { content: '\e8ac'; } /* '' */
.icon-power:before { content: '\e8ad'; } /* '' */
.icon-pipette:before { content: '\e8ae'; } /* '' */
.icon-brush:before { content: '\e8af'; } /* '' */
.icon-briefcase:before { content: '\e8b0'; } /* '' */
.icon-lifebuoy:before { content: '\e8b1'; } /* '' */
.icon-leaf:before { content: '\e8b2'; } /* '' */
.icon-factory:before { content: '\e8b3'; } /* '' */
.icon-plane-alt:before { content: '\e8b4'; } /* '' */
.icon-wind:before { content: '\e8b5'; } /* '' */
.icon-cloud-wind:before { content: '\e8b6'; } /* '' */
.icon-cloud-flash:before { content: '\e8b7'; } /* '' */
.icon-snow:before { content: '\e8b8'; } /* '' */
.icon-moon:before { content: '\e8b9'; } /* '' */
.icon-handshake:before { content: '\e8ba'; } /* '' */
.icon-flash:before { content: '\e8bb'; } /* '' */
.icon-cloud:before { content: '\e8bc'; } /* '' */
.icon-lightbulb-alt:before { content: '\e8bd'; } /* '' */
.icon-sun:before { content: '\e8be'; } /* '' */
.icon-shower:before { content: '\e8bf'; } /* '' */
.icon-globe-alt:before { content: '\e8c0'; } /* '' */
.icon-signpost:before { content: '\e8c1'; } /* '' */
.icon-globe:before { content: '\e8c2'; } /* '' */
.icon-contrast:before { content: '\e8c3'; } /* '' */
.icon-mobile:before { content: '\e8c4'; } /* '' */
.icon-tablet:before { content: '\e8c5'; } /* '' */
.icon-laptop:before { content: '\e8c6'; } /* '' */
.icon-desktop:before { content: '\e8c7'; } /* '' */
.icon-stop:before { content: '\e8c8'; } /* '' */
.icon-speech-bubbles:before { content: '\e8c9'; } /* '' */
.icon-pause:before { content: '\e8ca'; } /* '' */
.icon-fast-fw:before { content: '\e8cb'; } /* '' */
.icon-sun-alt:before { content: '\e8cc'; } /* '' */
.icon-rewind:before { content: '\e8cd'; } /* '' */
.icon-train:before { content: '\e8ce'; } /* '' */
.icon-record:before { content: '\e8cf'; } /* '' */
.icon-trophy:before { content: '\e8d0'; } /* '' */
.icon-eject:before { content: '\e8d1'; } /* '' */
.icon-eject-alt:before { content: '\e8d2'; } /* '' */
.icon-hard-hat:before { content: '\e8d3'; } /* '' */
.icon-bat1:before { content: '\e8d4'; } /* '' */
.icon-shuffle:before { content: '\e8d5'; } /* '' */
.icon-loop-alt:before { content: '\e8d6'; } /* '' */
.icon-loop:before { content: '\e8d7'; } /* '' */
.icon-arrows-cw:before { content: '\e8d8'; } /* '' */
.icon-cw:before { content: '\e8d9'; } /* '' */
.icon-up-small:before { content: '\e8da'; } /* '' */
.icon-right-small:before { content: '\e8db'; } /* '' */
.icon-left-small:before { content: '\e8dc'; } /* '' */
.icon-down-small:before { content: '\e8dd'; } /* '' */
.icon-up:before { content: '\e8de'; } /* '' */
.icon-right:before { content: '\e8df'; } /* '' */
.icon-left:before { content: '\e8e0'; } /* '' */
.icon-down:before { content: '\e8e1'; } /* '' */
.icon-man:before { content: '\e8e2'; } /* '' */
.icon-block:before { content: '\e8e3'; } /* '' */
.icon-move:before { content: '\e8e4'; } /* '' */
.icon-popup:before { content: '\e8e5'; } /* '' */
.icon-zoom-in:before { content: '\e8e6'; } /* '' */
.icon-zoom-out:before { content: '\e8e7'; } /* '' */
.icon-left-open:before { content: '\e8e8'; } /* '' */
.icon-plane:before { content: '\e8e9'; } /* '' */
.icon-right-open:before { content: '\e8ea'; } /* '' */
.icon-recycling:before { content: '\e8eb'; } /* '' */
.icon-scales:before { content: '\e8ec'; } /* '' */
.icon-shield:before { content: '\e8ed'; } /* '' */
.icon-lightbulb:before { content: '\e8ee'; } /* '' */
.icon-stopwatch:before { content: '\e8ef'; } /* '' */
.icon-cornering:before { content: '\e8f0'; } /* '' */
.icon-alarm-clock:before { content: '\e8f1'; } /* '' */
.icon-acceleration:before { content: '\e8f2'; } /* '' */
.icon-save-money:before { content: '\e8f3'; } /* '' */
.icon-easy:before { content: '\e8f4'; } /* '' */
.icon-safe:before { content: '\e8f5'; } /* '' */
.icon-right-open-1:before { content: '\e8f6'; } /* '' */
.icon-left-open-1:before { content: '\e8f7'; } /* '' */
