// Insert your styling here.
#mobile-menu {
  display: none;
  visibility: hidden;
}

@media @mobile {
  #mobile-menu {
    background: url("../images/mobile-nav.png") no-repeat scroll 0 0 transparent;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 30px;
    margin: 30px 0 0;
    width: 27px;
    visibility: visible;
  }
  .region-header {
    display: none;
  }
}

@media @notmobile {
  .region-header {
    display: block !important;
  }
  #block-menu-block-platform-menus-custom {
    float: left;
    ul {
      margin: 0;
    }
  }
  #block-menu-block-platform-menus-custom1 {
    float: right;
    width: 50%;
    ul {
      float: right;
      li {
        float: left;
        &:before {
          color: #4b4b4b;
          content: "|";
          margin-right: 10px;
          padding-left: 10px;
        }
        &:first-child:before {
          content: "";
          padding-left: 0px;
          margin-right: 0px;
        }
        a.twitter {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/twitter.png);
          background-repeat: no-repeat;
          padding-left: 40px;
          height: 22px;
          display: inline-block;
          line-height: 22px;
        }
        a.linkedin {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/linkedin.png);
          background-repeat: no-repeat;
          padding-left: 35px;
          height: 22px;
          display: inline-block;
          line-height: 22px;
        }
		a.facebook {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/facebook.png);
          background-repeat: no-repeat;
          padding-left: 35px;
          height: 22px;
          display: inline-block;
          line-height: 22px;
        }
      }
    }
  }

}

.header-separator {
  border-top: solid 1px @grayLight;
  height: 0px;
  margin-top: 20px;
}

#page-header {
  font-family: @headingsFontFamily;
  text-transform: uppercase;
  margin-bottom: 15px;
  position: relative;
  a {
    color: @grayDark;
  }

  .logo {
    float: left;
    @media @notmobile {
      margin-bottom: 30px;
      position: absolute;
      top: 30px;
      left: 0;
    }
    @media @mobile {
      margin-bottom: 20px;
    }
  }

  .region-header {
    @media @notmobile {
      .header-fix {
        margin-top: 30px;
      }

      .menu-block-platform_menus-3 {
        float: right;
        .menu li {
          display: inline;
        }
        .menu li a {
          display: inline;
        }
        .menu li:before {
          color: @grayDark;
          content: "|";
          margin-right: 10px;
          padding-left: 10px;
        }
        .menu li.first:before {
          display: none;
        }

      }

      #block-search-form {
        float: right;
        clear: right;
        width: 510px;
        #search-block-form,
        #search-block-form--2 {
          background-color: #e8e6dc;
          .search-query {
            border-radius: 0;
            background: none repeat scroll 0 0 transparent;
            border: none;
            padding-top: 7px;
            color: @black;
          }
          .btn {
            border-radius: 0;
            background: none repeat scroll 0 0 transparent;
            border: none;
          }
          input, button {
            color: @grayDark;
            font-family: @headingsFontFamily;
            text-transform: uppercase;
          }
          .icon-search {
            background-position: -48px 0;
          }
        }
      }
    }

    @media @mobile {
      background-color: @rsapale;
      clear: both;
      margin-bottom: 25px;
      padding: 10px;
      .header-fix {
        margin-top: 20px;
      }
      .menu-block-platform_menus-3 {
        .nav {
          margin-bottom: 0;
        }
        li {
          padding: 18px 0 20px 0;
          border-bottom: 2px solid #cac9c1;
          a {
            margin-left: 15px;
          }
        }
      }

      #block-menu-block-platform-menus-custom1 ul {
        padding: 0;
        margin: 0;
      }
      li {
        padding: 18px 0px 20px;
        border-bottom: 2px solid #CAC9C1;
        a.twitter {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/twitter.png);
          background-repeat: no-repeat;
          padding-left: 40px;
          line-height: 22px;
          height: 22px;
          margin-left: 15px;
        }
        a.linkedin {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/linkedin.png);
          background-repeat: no-repeat;
          padding-left: 35px;
          line-height: 22px;
          height: 22px;
          margin-left: 15px;
        }
		a.facebook {
          background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/facebook.png);
          background-repeat: no-repeat;
          padding-left: 35px;
          line-height: 22px;
          height: 22px;
          margin-left: 15px;
        }
      }

      #block-search-form {
        margin-bottom: 10px;
        #search-block-form,
        #search-block-form--2 {
          .search-query {
            border-radius: 0;
            background: #fff repeat scroll 0 0 transparent;
            border: none;
            padding-top: 7px;
            color: @black;
            height: 40px;
          }
          .btn {
            border-radius: 0;
            background: #fff;
            border: none;
            height: 40px;
            color: @black;
            .icon-search {
              background-position: -48px 0;
            }
          }
          input, button {
            color: @white;
            background-color: @white;
            font-family: @headingsFontFamily;
            text-transform: uppercase;
          }
        }
      }


    }
  }

  .breadcrumb {
    a {
      color: @purple;
    }
    background: none;
    clear: both;
    margin: 0;
    padding: 15px 0 0 0;
    font-weight: bold;
    li > .divider {
      color: @gray;
    }
  }
}
