// Insert your styling here.

body {
  @media @mobile {
      padding-left: 0;
      padding-right: 0;
      padding-top: 12px;
      .main-container {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
}

// Global changes
a,
a:hover,
a:focus {
  text-decoration: underline;
  color: @linkPurple;
}

table {
  background-color: @tableBackground;
  width: 100%;
  margin-bottom: @baseLineHeight;
  th {
    background-color: @tableBackgroundHeader;
    border-top: none;
    border-right: solid 16px @tableBorder;
    border-bottom: solid 4px @tableBorder;;
    border-left: none;
    padding: 12px;
  }
  th:last-child {
    border-right: none;
  }
  td {
    border-top: solid 2px @tableBorder;
    border-right: solid 16px @tableBorder;
    border-bottom: none;
    border-left: none;
    padding: 12px;
  }
  td:last-child {
    border-right: none;
  }
}

.lnk-icon {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  padding-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e8f6';
}

.lnk-callout {
  text-decoration: none;
  display: inline-block;
  font-family: rsa_sansmedium;
}

.lnk {
  display: inline-block;
  .ie7-inline-block();
  padding: 8px 18px;
  margin-bottom: 0; // For input.btn
  font-size: @baseFontSize;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: @blue;
  border: 1px solid #569099;
  *border: 0; // Remove the border to prevent IE7's black border on input:focus
  .border-radius(@baseBorderRadius);
  .ie7-restore-left-whitespace(); // Give IE7 some love
  color: @white;
  text-decoration: none;
  //font-family: Gill sans, Arial;
  max-height: 45px;
  letter-spacing: .1em;

  &:hover,
  &:focus {
    text-decoration: none;
    color: @white;
    background-color: #89c1c5;
  }
}

.lnk-primary {
  background-color: @btnPrimaryBackground;
  padding: 12px 22px;
  border: 1px solid #990066;
  font-size: @fontSizeLarge;
  max-height: 60px;
  text-transform: uppercase;
  letter-spacing: .05em;

  &:hover,
  &:focus {
    background-color: #df5db1;
  }
}

.container.no-gutter {
  width: 1200px;
  @media @mobile, @tablet, @normal {
    width: 100%;
    margin: 0;
  }
}

// Since the original design requires articles pages to differ from bootstrap
// this resets the width of the span9 class to the desired proportion.
@media (min-width: 1200px) {
  .row-fluid .span9.content-area  {
    width: 68.35897435897436%;
    *width: 68.30578286961266%;
  }
  .row-fluid .span3.sidebar {
    margin-left: 8.564102564102564%;
    *margin-left: 8.5109110747408616%;
  }
}

.nav > li > a:hover,
.nav > li > a:focus {
  background: none;
  color: @linkColorHover;
  text-decoration: underline;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  color: @gray;
}

h1 {
  color: @blue;
  font-size: 3.125em;
}

@media @mobile {
  h1 {
    font-size: 2.64em;
  }
}

h2 {
  font-size: 1.725em;
}

h3 {
  font-size: @fontSizeLarge;
}

h4 {
  font-size: 1.14em;
}

h1 a, h2 a,
.breadcrumb a,
.companies-header a,
.homepage-menu-sections .views-field-title a {
  color: @linkPurple;
  text-decoration: underline;
}

h1 a:hover, h2 a:hover,
.breadcrumb a:hover,
.companies-header a:hover,
.homepage-menu-sections .views-field-title a:hover {
  color: @linkPurpleHover;
  text-decoration: underline;
}

.btn {
  background-color: #6CB1B6;
  background-image: none;
  border: 1px solid #569099;
  box-shadow: none;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-shadow: none;
}

select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input, .resizable-textarea textarea {
  border-radius: 0 0 0 0;
}

.styled-select select {
   background: transparent;
   width: 100%;
   padding: 5px;
   font-size: 12px;
   line-height: 1;
   border: 0;
   border-radius: 0;
   height: 30px;
   -webkit-appearance: none;
}

.styled-select {
   width: 100%;
   height: 30px;
   overflow: hidden;
   background: url("../images/select-arrow.png") no-repeat 98% center white;
   border: 1px solid #d8d8d8;
}

// Home page sections menu.
.homepageBlockTitle {
  color: @gray;
  font-family: @headingsFontFamily;
  font-size: 24px;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@homepageColumnWidthSpan4: 32.6667%;

.homepageColumnSpan4 {
  @media (min-width: 768px) {
    width: @homepageColumnWidthSpan4;
    float: left;
  }
}

.homepageMarginSpan4 {
  @media (min-width: 768px) {
    margin-left: 1%;
  }
}

.borderTop (@borderColor: @pink) {
  border-top: solid 5px @borderColor;
}

.homepage-menu-sections.span4 {
  padding-top: 10px;
  margin-bottom: 35px;
  .links {
    margin-top: 25px;
  }
  .views-field-title {
    padding-bottom: 8px;
    margin-bottom: 24px;
    font-family: rsa_sansregular;
    font-size: 1.6em;
    line-height: 1.4em;
    color: @gray;
    text-transform: uppercase;
  }
  .views-field-body {
    h3 {
      font-size: 1.3em;
    }
    a, a:hover, a:visited {
      .lnk-callout;
      margin-bottom: 8px;
      display: table-row;
    }
    a:before {
      .lnk-icon;
      display: table-cell;
    }
  }
}

.homepage-menu-sections.item1 {
  margin-left: 0;
  @media @normal, @wide {
    padding-right: 53.333px;
  }
  .views-field-title {
    border-bottom: 3px solid @pink;
  }
  .views-field-body {
    a, i, h3 {
      color: @pink;
    }
    h3 {
      font-family: rsa_sansregular;
    }
  }
}
.homepage-menu-sections.item2 {
  @media @normal, @wide {
    padding-right: 26.666px;
    padding-left: 26.666px;
  }
  .homepageMarginSpan4;
  .views-field-title {
    border-bottom: 3px solid @teal;
  }
  .views-field-body {
    a, i, h3 {
      color: @teal;
    }
    h3 {
      font-family: rsa_sansregular;
    }
  }
  .separator {
    margin-top: 20px;
    border-top: solid 1px @teal;
  }
}
.homepage-menu-sections.item3 {
  @media @normal, @wide {
    padding-left: 53.333px;
  }
  .views-field-title {
    border-bottom: 3px solid @purple;
  }
  .views-field-body {
    a, i, h3 {
      color: @purple;
    }
    h3 {
      font-family: rsa_sansregular;
    }
  }
}
.homepage-companies.span12 {
  @media @mobile {
    display: none;
  }
  float: none;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
    .companies-header {
      @media @tablet {
        display: none;
      }
      text-transform: uppercase;
    }
    > div {
      @media @tablet {
        flex-basis: 27.2%;
      }
      flex-basis: 21.2%;
      font-family: rsa_sansmedium;
      font-size: 1rem;
      background: @rsapale;
      margin-right: 1.5%;
      padding-top: 7px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 7px;
    }
    > div:last-child {
      margin-right: 0;
    }
    .item1, .item2, .item3 {
      position: relative;
      div {
        @media @wide {
          display: inline;
        }
        vertical-align: middle;
      }
      .views-field-field-logo-1 {
        @media @wide {
          position: absolute;
          top: 2px;
        }
        img {
          height: 22px;
          width: auto;
          // margin-bottom: 8px;
        }
      }
      .views-field-field-link {
        margin-left: 0;
        margin-top: 5px;
        @media @wide {
          margin-left: 55px;
        }
        a {
          text-decoration: none;
        }
        a:after {
          font-family: rsa-icons;
          font-style: normal;
          font-weight: 400;
          speak: none;
          text-decoration: inherit;
          width: 1em;
          padding-left: .5em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e8f6";
        }
      }
    }
    .item1 {
      .views-field-field-logo-1 {
        img {
          height: 26px;
        }
      }
      .views-field-field-link {
        margin-top: 0;
        @media @wide {
          margin-left: 102px;
        }
      }
    }
}
#page-header .region-header > .homepage-companies.span12 {
  display: none;
  @media @mobile {
    display: block;    
  }
  .companies-header {
    margin-bottom: 3px;
    text-transform: uppercase;
  }
  > div {
    font-size: .9rem;
    padding-top: 10px;
    padding-bottom: 8px;
    text-transform: none;
  }
  .item1, .item2, .item3 {
    div {
      display: inline;
    }
    img {
      margin-right: 2%;
    }
    a {
      color: @purple;
    }
  }
  .item2, .item3 {
    img {
      padding-bottom: 6px;
    }
  }
}
.badges-container-homepage {
  .badges {
    margin-top: 20px;
    @media @mobile {
      margin-top: 40px;
    }
    .badges-header {
      padding-bottom: 8px;
      margin-bottom: 20px;
      font-family: rsa_sansregular;
      font-size: 1.6em;
      color: @gray;
      text-transform: uppercase;
      border-bottom: solid 3px @purple;
    }
    .span4 {
      .homepageColumnSpan4;
      @media @mobile {
        padding-top: 15px;
      }
      position: relative;
      @media @mobile {
        margin-bottom: 30px;
      }
      h2.block-title {
        font-size: 1.3em;
        margin: 20px 0px;
        position: relative;
        z-index: 100;
        max-width: 66%;
      }
    }
    .row-fluid [class*="item"] {
      .homepageMarginSpan4;
    }
    .row-fluid [class*="item"]:first-child {
      margin-left:0;
    }
    .item1 {
      @media @normal, @wide {
        padding-right: 53.333px;
      }
    }
    .item2 {
      @media @normal, @wide {
        padding-right: 26.666px;
        padding-left: 26.666px;
      }
    }
    .item3{
      @media @normal, @wide {
        padding-left: 53.333px;
      }
    }
    .badge-link {
      margin-top: 12px;
      text-align: right;
      a, a:hover, a:visited {
        .lnk-callout;
        color: @purple;
        display: table-row;
      }
      a:before {
        .lnk-icon;
        display: table-cell;
      }
    }
    .badge-image {
      width: 100%;
      img {
        @media @mobile {
          width: auto;
        }
      }
      z-index: 1;
    }
    .badge-spacer, .badge-spacer img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 190px;
      @media @mobile {
        height: 140px;
      }
      z-index: 10;
    }
  }
}

.badges-container {
  .badges {
    .span4 {
      .homepageColumnSpan4;
      padding: 5px 0 0 14px;
      height: 190px;
      @media @mobile {
        height: 140px;
        padding-top: 15px;
      }
      background-color: @rsapale;
      position: relative;
      @media @mobile {
        margin-bottom: 30px;
      }
      h2.block-title {
        .homepageBlockTitle;
        margin-top: 0;
        position: relative;
        z-index: 100;
        max-width: 66%;
      }
    }
    .row-fluid [class*="item"] {
      .homepageMarginSpan4;
    }
    .row-fluid [class*="item"]:first-child {
      margin-left:0;
    }
    .badge-link {
      position: relative;
      z-index: 10;
    }
    .badge-image {
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      // height: auto;
      img {
        @media @mobile {
          max-height: 140px;
          width: auto;
        }
      }
      z-index: 1;
    }
    .badge-spacer, .badge-spacer img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 190px;
      @media @mobile {
        height: 140px;
      }
      z-index: 10;
    }
  }

}

// Generic Page Styles.
.content-area {
  h1.page-header {
    border: none;
    margin: 0;
    @media @mobile {
      margin-top: 20px;
    }
  }
  .btn {
    margin: 10px 0;
  }
  .borderTop (@rsapale);
  @media @notmobile {
    padding-top: 40px;
  }
  .field-name-field-preamble {
    font-size: 1.14em;
    line-height: 1.41em;
    margin-bottom: 10px;
  }
  .nav {
  // margin-top: 100px;
    li {
      font-family: @headingsFontFamily;
      text-transform: uppercase;
      font-size: 1.14em;
      line-height: 1.71em;
      a:hover, a.active {
        color: @linkPurpleHover;
      }
    }
  }
}
.front {
  .content-area {
    border-top: 0;
  }
}

.sidebar {
  .menu {
    .borderTop (@pink);
    font-size: 1.285em;
    line-height: 1.715em;
  }
  .nav li {
    padding: 18px 0 20px 0;
    border-bottom: 2px solid #cac9c1;
    .last {
      border-bottom: none;
    }
    ul li {
      border-bottom: none;
      font-size: .9em;
      padding: 18px 0 0 0;
      & .active {
        text-decoration: none;
      }
    }
    a {
      color: @linkPurple;
    }
    a:hover,
    a:focus,
    a.active-trail {
      color: @gray;
    }
  }
  @media @mobile {
    margin-top: 40px;
  }

  .view-display-id-block {
    margin-top: 40px;
    font-size: 1.285em;
    line-height: 1.715em;
    .views-field-title {
      padding: 18px 0 20px 0;
    }
    .views-field-field-link-x {
      .borderTop (@blue);
    }
    ul {
      margin-left: 0;
      margin-bottom: 18px;
      list-style: none;
      li {
        padding: 18px 0 20px 0;
        border-bottom: 2px solid #cac9c1;
        .last {
          border-bottom: none;
        }
        a {
          color: @linkPurple;
        }
      }
    }
  }
}

#homepage-banner {
  position: relative;
  text-align: center;
  @media  @notmobile {
    margin-bottom: 20px;
  }
  .homepage-banner-caption {
    position: absolute;
    padding: 20px 80px;
    background-color: @white;
    width: 50%;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    -webkit-box-shadow: 0px 12px 6px -6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 12px 6px -6px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 12px 6px -6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    @media @tablet {
      width: 60%;
    }
    @media @mobile {
      padding: 0px;
      margin: 20px;
      width: auto;
      bottom: 0px;
      position: relative;;
      -webkit-box-shadow: none;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow:    none;  /* Firefox 3.5 - 3.6 */
      box-shadow:         none;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    }
  }
  .homepage-banner-title {
    position: relative;
    top: 0;
    margin-top: 0;
    margin-bottom: 12px;
    padding: 0;
    h1 {
      text-transform: none;
      color: @grayDark;
      font-size: 3em;
      padding: 0;
      margin: 0;
      line-height: 64px;
      font-family: rsa_sansmedium;
      @media @tablet, @mobile {
        font-size: 2.6em;
        line-height: 40px;
      }
      @media @mobile {
        font-size: 2.2em;
        line-height: 32px;
      }
    }
    z-index: 100;
  }
  .homepage-banner-subtext {
    position: relative;
    color: @grayDark;
    font-size: 1.4em;
    line-height: 28px;
    @media @tablet {
      font-size: 1.4em;
      line-height: 24px;
    }
  }
  .homepage-banner-image {
    z-index: 2;
    top: 0;
    margin-bottom: 20px;
    img {
      width: 100%;
    }

    @media @tablet, @mobile {
      min-height: 150px;
      img {
        min-height: 150px;
      }
    }
    @media @notmobile {
      margin: 35px 45px;
    }
  }
  .banner-ribbon-left {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 35%;
  }
  .banner-ribbon-right {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
    max-width: 35%;
    @media @mobile {
      display: none;
    }
  }
  z-index: 0;
}

// Search page styles.
.page-search {
  #page-header {
    margin-bottom: 0;
  }
  .content-area {
    border-top: 0;
    padding-top: 0;
    h2 {
      border-bottom: solid 5px @rsapale;
      padding-bottom: 10px;
    }
  }
  .search-results {
    .search-result {
      margin-top: 30px;
    }
    h3.title {
      font-size: 1.725em;
      text-transform: uppercase;
      a {
        color: @linkPurple;
      }
      a:hover {
        color: @linkPurpleHover;
      }
    }
  }
}

// Contact page styles.
.triptych {
  clear: both;
  position: relative;
  z-index: 0;

  @media @notmobile { // Desktop Styles.
    .ptux, .contact-icon {
      float: left;
      margin-right: 9.5%;
      width: 27%;
      }
    .ptux-3, .cicon-webform {
      margin-right: 0;
    }
    .contact-icon {
      background: url(../images/contact-icons.png) no-repeat;
      height: 45px;
      &.cicon-phone {
        background-position: 0 0;
      }
      &.cicon-locations {
        background-position: 0 -50px;
      }
      &.cicon-webform {
        background-position: 0 -100px;
      }
    }



  } // End of Desktop styles.


  @media @mobile {
    .contact-columns {
      clear: both;
    }
    .contact-icon {
      background: url(../images/contact-icons.png) no-repeat;
      float: left;
      height: 60px;
      width: 33%;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
      }
      &.cicon-phone {
        background-position: 0 -160px;
      }
      &.cicon-locations {
        background-position: center -240px;
      }
      &.cicon-webform {
        background-position: right -320px;
      }
    }
    .ptux {
      width: 100%;
      }
      .ptux-1 {
        display: block;
        &:before {
          border-bottom: 15px solid #E8E6DC;
          border-left: 15px solid rgba(0, 0, 0, 0);
          border-right: 15px solid rgba(0, 0, 0, 0);
          content: "";
          display: inline-block;
          height: 0;
          left: 18px;
          position: absolute;
          top: 65px;
          width: 0;
        }
      }
      .ptux-2 {
        display: none;
        &:before {
          border-bottom: 15px solid #E8E6DC;
          border-left: 15px solid rgba(0, 0, 0, 0);
          border-right: 15px solid rgba(0, 0, 0, 0);
          content: "";
          display: inline-block;
          height: 0;
          left: 44%;
          position: absolute;
          top: 65px;
          width: 0;
        }
      }
      .ptux-3 {
        display: none;
        &:before {
          border-bottom: 15px solid #E8E6DC;
          border-left: 15px solid rgba(0, 0, 0, 0);
          border-right: 15px solid rgba(0, 0, 0, 0);
          content: "";
          display: inline-block;
          height: 0;
          right: 26px;
          position: absolute;
          top: 65px;
          width: 0;
        }
      }
    section {
      background-color: @rsapale;
      padding: 20px 10px;
    }

  }



  // UI Acordion Styles.
  .ui-accordion {
    width: 100%;
  }
  .ui-accordion .ui-accordion-header {
    cursor: pointer;
    margin-top: 1px;
    position: relative;
  }
  .ui-accordion .ui-accordion-li-fix {
    display: inline;
  }
  .ui-accordion .ui-accordion-header-active {
    border-bottom: 0 none !important;
  }
  .ui-accordion .ui-accordion-header a {
    display: block;
    font-size: 1em;
    padding: 0.75em 0.5em 0.75em 0.7em;
  }
  .ui-accordion-icons .ui-accordion-header a {
    padding-left: 0;
  }
  .ui-accordion .ui-accordion-header .ui-icon {
    display: none;
  }
  .ui-accordion .ui-accordion-content {
    border-top: 0 none;
    display: none;
    margin-bottom: 2px;
    margin-top: -2px;
    overflow: auto;
    padding: 0 15px;
    position: relative;
    top: 1px;
  }
  .ui-accordion .ui-accordion-content-active {
    display: block;
  }

  .ui-corner-all {
    border-radius: 0;
  }
  .ui-helper-reset {
    font-size: 0.925em;
    line-height: 1.3;
    list-style: none outside none;
  }

  .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border: 0;
    border-top: 1px solid @rsapale;
  }
  .ui-widget-content {
    background: none;
    border: 0
  }

  @media @notmobile {
    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default,
    .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
      border: 0;
      background: none;
      border-top: 2px solid @rsapale;
    }
  }

  @media @mobile {
    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default,
    .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
      border: 0;
      background: none;
      border-top: 2px solid #cac9c1;
    }
  }

  .views-row-1 .ui-state-active,
  .views-row-1 .ui-state-default {
    border: 0;
  }
}

//Inherited Accordion CSS from rsabroker.ca/backtobasics -- JS required (rsagroup.js)
.accordion {
  margin-bottom: 22px;
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}

#gs_accordion .accordion-group {
    border-radius: 0px;
    border: none;
    border-bottom: 3px solid #000;
}
#gs_accordion .accordion-inner {
    border: none;
}
.gs_btn_icon {
    width: 10%;
    vertical-align: middle;
    float: right;
}
#gs_accordion .gs_icon {
    width: 6%;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 12px;
    margin-bottom: 12px;
}
#gs_accordion .accordion-heading a {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
   /* color: #6f2c91;
    font-weight: bold;
    font-family: 'RSA Sans Medium';
    font-size: 14px;*/
    width: 80%;
}
#gs_accordion .accordion-heading a:hover {
    color: #00B5D5;
}
#gs_gradient {
    background-image: url('/sites/rsagroup.ca/files/gs_gradient.png');
    background-size: cover;
    color: white;
    font-size: 10px;
    line-height: 12px;
}
#gs_accordion .gs_indicator {
    float: right;
    /*padding-top: 15px;*/
      
    img {
      transform: translateY(1.4em);
    }

    @media (max-width: 766px) {
      img {
        transform: translateY(0.5em);
      }
    } 
}
#gs_accordion .accordion-inner {
    padding-left: 0px;
    padding-right: 0px;
}
.gs_mobile_icon {
    float: left;
    vertical-align: middle;
    margin-right: 10px;
}
#gs_accordion .gs_mobile_icon {
    width: 10%;
}


// Make a Claim page
.make-claim-pg {
  .hero {
    padding-bottom: 30px;
    border-bottom: 3px solid #000;
  
    h1 {
      padding-top: 0.6em;
    }
    
    .sub-section {
      margin-bottom: 30px;

      .cta {
        padding: 28px 0;
        text-align: center;
        
        img {
          height: 110px;
        }
      }

      span.text-underline {
        text-decoration: underline;
      }
    } 
  }
  
  .accordion {
    .accordion-group {
      .accordion-body {        
        .accordion-inner {
          padding-bottom: 20px;
                
          .contact-info {
            margin-bottom: 10px;
            
            .division {
              display: inline-block;
              vertical-align: top;
              width: 45%;
              
              @media (max-width: 766px) {
                width: 55%;
              }
            }

            .contact-details {
              display: inline-block;
              width: 50%;
              
              @media (max-width: 766px) {
                width: 40%;
              }
              
              .br-big {
                line-height: 2em;
              }
            }
          }      
        }
      }
    }
  }
  
  .phone-number {
    white-space: nowrap;
  }
}
// Make a Claim page styling ends

.not-front {
  .span12 {
    margin-left: 0;
    #block-views-badge-block {
      margin-top: 20px;
    }
  }
}
.webform-client-form .captcha{
  margin-top: 25px;
}

// Chat button styling override
a.chat-button{
  font-family: rsa_sansregular;
	font-size: 12px;
	background: #E30989;
	
	&::before{
		font-family: "chat-rsa";
		content: '\e900';
		font-size: 20px;
		text-align: left;
	}
  
	@media (min-width: 768px){
		width: 40px;
		overflow: visible;
		line-height: 16px;
		
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			padding-top: 12px;
		}
		&::before{  
			font-size: 30px;
			margin-top: 10px;
			
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				margin-bottom: 20px;
			}
		}
	}
  
    @media (max-width: 767px){
		padding-top: 0px;
		padding-bottom: 5px;
		&::before{
			position: relative;
			top: 3px;
			margin-right: 5px;
		}
	}
  
  &:hover{
    text-decoration: none;
  }
}

/* Turn off Feedbackify tab on the right rail */
#feedbackify .fby-tab-r{
  display: none;
}

/* Feedbackify footer styling */
.feedback-bar {
  position: relative;
  top: 45px;
  background-color: #E3E3E3;
  padding: 1.5em;
  text-align: center;
  a{
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      /* color: @color-link-hover; */
    }
  }
}