// Insert your styling here.

.footer {
  background-color: @grayDarker;
  text-align: center;
  width: 100%;
  a {
    color: @white;
    font-weight: bold;
  }
  .nav > li > a:hover {
    color: @white;
  }
  @media (min-width: 768px) {
    .menu li {
      display: inline;
    }
    .menu li:after {
      color: @white;
      content: "|";
      margin-right: 6px;
      padding-left: 10px;
    }
    .menu li.last:after {
      display: none;
    }
    .menu li a {
      display: inline; /* need to remove block */
    }
  }

  #block-menu-menu-footer-menu {
    /* Add link height for mobile view */
    @media (max-width: 767px) {
      li {
        padding-top: 25px;
      }
      li.first {
        padding-top: 0;
      }
    }
  }

  #block-menu-menu-social-links {
    margin-top: 40px;
    .menu li {
      display: inline;
      a {
        height: 25px;
        display: inline-block;
        padding-top: 5px;
      }
    }
    .menu li:after {
      content: "";
      margin-right: 6px;
      padding-left: 10px;
    }
    .twitter {
      background-image: url("../images/twitter.png");
      background-repeat: no-repeat;
      padding-left: 40px;
    }
    .linkedin {
      background-image: url("../images/linkedin.png");
      background-repeat: no-repeat;
      padding-left: 35px;
    }
	.facebook {
      background-image: url("../images/facebook.png");
      background-repeat: no-repeat;
      padding-left: 35px;
    }
  }

  #boxes-box-copyright_note {
    color: #999;
    margin-top: 40px;
  }

}
