@font-face {
  font-family: 'rsa_sansregular';
  src: url('../fonts/RSASans_Regular.eot');
  src: url('../fonts/RSASans_Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_Regular.woff') format('woff'), url('../fonts/RSASans_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rsa_sansregular_italic';
  src: url('../fonts/RSASans_RegularItalic.eot');
  src: url('../fonts/RSASans_RegularItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_RegularItalic.woff') format('woff'), url('../fonts/RSASans_RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rsa_sanslight';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_Light.eot');
  src: url('../fonts/RSASans_Light.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_Light.woff') format('woff'), url('../fonts/RSASans_Light.ttf') format('truetype'), url('../fonts/RSASans_Light.svg#rsasans_light') format('svg');
}
@font-face {
  font-family: 'rsa_sanslight_italic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_LightItalic.eot');
  src: url('../fonts/RSASans_LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_LightItalic.woff') format('woff'), url('../fonts/RSASans_LightItalic.ttf') format('truetype'), url('../fonts/RSASans_LightItalic.svg#rsasans_light') format('svg');
}
@font-face {
  font-family: 'rsa_sansmedium';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_Medium.eot');
  src: url('../fonts/RSASans_Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_Medium.woff') format('woff'), url('../fonts/RSASans_Medium.ttf') format('truetype'), url('../fonts/RSASans_Medium.svg#rsasans_medium') format('svg');
}
@font-face {
  font-family: 'rsa_sansmedium_italic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_MediumItalic.eot');
  src: url('../fonts/RSASans_MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_MediumItalic.woff') format('woff'), url('../fonts/RSASans_MediumItalic.ttf') format('truetype'), url('../fonts/RSASans_MediumItalic.svg#rsasans_medium') format('svg');
}
@font-face {
  font-family: 'rsa_sansbold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_Bold.eot');
  src: url('../fonts/RSASans_Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_Bold.woff') format('woff'), url('../fonts/RSASans_Bold.ttf') format('truetype'), url('../fonts/RSASans_Bold.svg#rsasans_medium') format('svg');
}
@font-face {
  font-family: 'rsa_sansbold_italic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/RSASans_BoldItalic.eot');
  src: url('../fonts/RSASans_BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/RSASans_BoldItalic.woff') format('woff'), url('../fonts/RSASans_BoldItalic.ttf') format('truetype'), url('../fonts/RSASans_BoldItalic.svg#rsasans_medium') format('svg');
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 28px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover,
a:active {
  outline: 0;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.google-maps img {
  max-width: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
body {
  margin: 0;
  font-family: rsa_sansregular, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #666;
  text-decoration: underline;
}
.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}
.row {
  margin-left: -20px;
  *zoom: 1;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}
.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.span12 {
  width: 940px;
}
.span11 {
  width: 860px;
}
.span10 {
  width: 780px;
}
.span9 {
  width: 700px;
}
.span8 {
  width: 620px;
}
.span7 {
  width: 540px;
}
.span6 {
  width: 460px;
}
.span5 {
  width: 380px;
}
.span4 {
  width: 300px;
}
.span3 {
  width: 220px;
}
.span2 {
  width: 140px;
}
.span1 {
  width: 60px;
}
.offset12 {
  margin-left: 980px;
}
.offset11 {
  margin-left: 900px;
}
.offset10 {
  margin-left: 820px;
}
.offset9 {
  margin-left: 740px;
}
.offset8 {
  margin-left: 660px;
}
.offset7 {
  margin-left: 580px;
}
.offset6 {
  margin-left: 500px;
}
.offset5 {
  margin-left: 420px;
}
.offset4 {
  margin-left: 340px;
}
.offset3 {
  margin-left: 260px;
}
.offset2 {
  margin-left: 180px;
}
.offset1 {
  margin-left: 100px;
}
.row-fluid {
  width: 100%;
  *zoom: 1;
}
.row-fluid:before,
.row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.row-fluid:after {
  clear: both;
}
.row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 28px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
}
.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.row-fluid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.12765957%;
}
.row-fluid .span12 {
  width: 100%;
  *width: 99.94680851%;
}
.row-fluid .span11 {
  width: 91.4893617%;
  *width: 91.43617021%;
}
.row-fluid .span10 {
  width: 82.9787234%;
  *width: 82.92553191%;
}
.row-fluid .span9 {
  width: 74.46808511%;
  *width: 74.41489362%;
}
.row-fluid .span8 {
  width: 65.95744681%;
  *width: 65.90425532%;
}
.row-fluid .span7 {
  width: 57.44680851%;
  *width: 57.39361702%;
}
.row-fluid .span6 {
  width: 48.93617021%;
  *width: 48.88297872%;
}
.row-fluid .span5 {
  width: 40.42553191%;
  *width: 40.37234043%;
}
.row-fluid .span4 {
  width: 31.91489362%;
  *width: 31.86170213%;
}
.row-fluid .span3 {
  width: 23.40425532%;
  *width: 23.35106383%;
}
.row-fluid .span2 {
  width: 14.89361702%;
  *width: 14.84042553%;
}
.row-fluid .span1 {
  width: 6.38297872%;
  *width: 6.32978723%;
}
.row-fluid .offset12 {
  margin-left: 104.25531915%;
  *margin-left: 104.14893617%;
}
.row-fluid .offset12:first-child {
  margin-left: 102.12765957%;
  *margin-left: 102.0212766%;
}
.row-fluid .offset11 {
  margin-left: 95.74468085%;
  *margin-left: 95.63829787%;
}
.row-fluid .offset11:first-child {
  margin-left: 93.61702128%;
  *margin-left: 93.5106383%;
}
.row-fluid .offset10 {
  margin-left: 87.23404255%;
  *margin-left: 87.12765957%;
}
.row-fluid .offset10:first-child {
  margin-left: 85.10638298%;
  *margin-left: 85%;
}
.row-fluid .offset9 {
  margin-left: 78.72340426%;
  *margin-left: 78.61702128%;
}
.row-fluid .offset9:first-child {
  margin-left: 76.59574468%;
  *margin-left: 76.4893617%;
}
.row-fluid .offset8 {
  margin-left: 70.21276596%;
  *margin-left: 70.10638298%;
}
.row-fluid .offset8:first-child {
  margin-left: 68.08510638%;
  *margin-left: 67.9787234%;
}
.row-fluid .offset7 {
  margin-left: 61.70212766%;
  *margin-left: 61.59574468%;
}
.row-fluid .offset7:first-child {
  margin-left: 59.57446809%;
  *margin-left: 59.46808511%;
}
.row-fluid .offset6 {
  margin-left: 53.19148936%;
  *margin-left: 53.08510638%;
}
.row-fluid .offset6:first-child {
  margin-left: 51.06382979%;
  *margin-left: 50.95744681%;
}
.row-fluid .offset5 {
  margin-left: 44.68085106%;
  *margin-left: 44.57446809%;
}
.row-fluid .offset5:first-child {
  margin-left: 42.55319149%;
  *margin-left: 42.44680851%;
}
.row-fluid .offset4 {
  margin-left: 36.17021277%;
  *margin-left: 36.06382979%;
}
.row-fluid .offset4:first-child {
  margin-left: 34.04255319%;
  *margin-left: 33.93617021%;
}
.row-fluid .offset3 {
  margin-left: 27.65957447%;
  *margin-left: 27.55319149%;
}
.row-fluid .offset3:first-child {
  margin-left: 25.53191489%;
  *margin-left: 25.42553191%;
}
.row-fluid .offset2 {
  margin-left: 19.14893617%;
  *margin-left: 19.04255319%;
}
.row-fluid .offset2:first-child {
  margin-left: 17.0212766%;
  *margin-left: 16.91489362%;
}
.row-fluid .offset1 {
  margin-left: 10.63829787%;
  *margin-left: 10.53191489%;
}
.row-fluid .offset1:first-child {
  margin-left: 8.5106383%;
  *margin-left: 8.40425532%;
}
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}
[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}
.container {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  *zoom: 1;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.container-fluid:after {
  clear: both;
}
p {
  margin: 0 0 9px;
}
.lead {
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: 200;
  line-height: 27px;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}
.muted {
  color: #ccc;
}
a.muted:hover,
a.muted:focus {
  color: #b3b3b3;
}
.text-warning {
  color: #c09853;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #a47e3c;
}
.text-error {
  color: #b94a48;
}
a.text-error:hover,
a.text-error:focus {
  color: #953b39;
}
.text-info {
  color: #3a87ad;
}
a.text-info:hover,
a.text-info:focus {
  color: #2d6987;
}
.text-success {
  color: #468847;
}
a.text-success:hover,
a.text-success:focus {
  color: #356635;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 9px 0;
  font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 18px;
  color: #5a2d7e;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #ccc;
}
h1,
h2,
h3 {
  line-height: 36px;
}
h1 {
  font-size: 38.5px;
}
h2 {
  font-size: 31.5px;
}
h3 {
  font-size: 24.5px;
}
h4 {
  font-size: 17.5px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 11.9px;
}
h1 small {
  font-size: 24.5px;
}
h2 small {
  font-size: 17.5px;
}
h3 small {
  font-size: 14px;
}
h4 small {
  font-size: 14px;
}
.page-header {
  padding-bottom: 8px;
  margin: 18px 0 27px;
  border-bottom: 1px solid #eee;
}
ul,
ol {
  padding: 0;
  margin: 0 0 9px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 18px;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
}
ul.inline > li,
ol.inline > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-bottom: 18px;
}
dt,
dd {
  line-height: 18px;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 9px;
}
.dl-horizontal {
  *zoom: 1;
}
.dl-horizontal:before,
.dl-horizontal:after {
  display: table;
  content: "";
  line-height: 0;
}
.dl-horizontal:after {
  clear: both;
}
.dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dl-horizontal dd {
  margin-left: 180px;
}
hr {
  margin: 18px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #ccc;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 18px;
  border-left: 5px solid #eee;
}
blockquote p {
  margin-bottom: 0;
  font-size: 17.5px;
  font-weight: 300;
  line-height: 1.25;
}
blockquote small {
  display: block;
  line-height: 18px;
  color: #ccc;
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eee;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\00A0 \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 18px;
  font-style: normal;
  line-height: 18px;
}
code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 12px;
  color: #4b4b4b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  white-space: nowrap;
}
pre {
  display: block;
  padding: 8.5px;
  margin: 0 0 9px;
  font-size: 13px;
  line-height: 18px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
pre.prettyprint {
  margin-bottom: 18px;
}
pre code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
form {
  margin: 0 0 18px;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 36px;
  color: #4b4b4b;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
legend small {
  font-size: 13.5px;
  color: #ccc;
}
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
input,
button,
select,
textarea {
  font-family: rsa_sansregular, Helvetica, Arial, sans-serif;
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  height: 18px;
  padding: 4px 6px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 18px;
  color: #666;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  vertical-align: middle;
}
input,
textarea,
.uneditable-input {
  width: 206px;
}
textarea {
  height: auto;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  *margin-top: 0;
  /* IE7 */
  margin-top: 1px \9;
  /* IE8-9 */
  line-height: normal;
}
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  height: 28px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */
  line-height: 28px;
}
select {
  width: 220px;
  border: 1px solid #ccc;
  background-color: #fff;
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.uneditable-input,
.uneditable-textarea {
  color: #ccc;
  background-color: #fcfcfc;
  border-color: #ccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #ccc;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #ccc;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ccc;
}
.radio,
.checkbox {
  min-height: 18px;
  padding-left: 20px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}
.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}
input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 926px;
}
input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 846px;
}
input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 766px;
}
input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 686px;
}
input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 606px;
}
input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 526px;
}
input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 446px;
}
input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 366px;
}
input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 286px;
}
input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 206px;
}
input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 126px;
}
input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 46px;
}
.controls-row {
  *zoom: 1;
}
.controls-row:before,
.controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.controls-row:after {
  clear: both;
}
.controls-row [class*="span"],
.row-fluid .controls-row [class*="span"] {
  float: left;
}
.controls-row .checkbox[class*="span"],
.controls-row .radio[class*="span"] {
  padding-top: 5px;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eee;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
.control-group.warning .control-label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}
.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
}
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #b94a48;
}
.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #b94a48;
}
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  border-color: #b94a48;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}
.control-group.success .control-label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}
.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
.control-group.info .control-label,
.control-group.info .help-block,
.control-group.info .help-inline {
  color: #3a87ad;
}
.control-group.info .checkbox,
.control-group.info .radio,
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  color: #3a87ad;
}
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  border-color: #3a87ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  border-color: #2d6987;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
.control-group.info .input-prepend .add-on,
.control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
  padding: 17px 20px 18px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}
.help-block,
.help-inline {
  color: #595959;
}
.help-block {
  display: block;
  margin-bottom: 9px;
}
.help-inline {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  display: inline-block;
  margin-bottom: 9px;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu,
.input-append .popover,
.input-prepend .popover {
  font-size: 14px;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  vertical-align: top;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 18px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  background-color: #eee;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-search .input-append .search-query {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 9px;
}
legend + .control-group {
  margin-top: 18px;
  -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
  margin-bottom: 18px;
  *zoom: 1;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-label {
  float: left;
  width: 160px;
  padding-top: 5px;
  text-align: right;
}
.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  margin-left: 180px;
  *margin-left: 0;
}
.form-horizontal .controls:first-child {
  *padding-left: 180px;
}
.form-horizontal .help-block {
  margin-bottom: 0;
}
.form-horizontal input + .help-block,
.form-horizontal select + .help-block,
.form-horizontal textarea + .help-block,
.form-horizontal .uneditable-input + .help-block,
.form-horizontal .input-prepend + .help-block,
.form-horizontal .input-append + .help-block {
  margin-top: 9px;
}
.form-horizontal .form-actions {
  padding-left: 180px;
}
table {
  max-width: 100%;
  background-color: #f3f3f3;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  width: 100%;
  margin-bottom: 18px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #fff;
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid #fff;
}
.table .table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #fff;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #fff;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
.table td.span1,
.table th.span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}
.table td.span2,
.table th.span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}
.table td.span3,
.table th.span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}
.table td.span4,
.table th.span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}
.table td.span5,
.table th.span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}
.table td.span6,
.table th.span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}
.table td.span7,
.table th.span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}
.table td.span8,
.table th.span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}
.table td.span9,
.table th.span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}
.table td.span10,
.table th.span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}
.table td.span11,
.table th.span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}
.table td.span12,
.table th.span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}
.table tbody tr.success > td {
  background-color: #dff0d8;
}
.table tbody tr.error > td {
  background-color: #f2dede;
}
.table tbody tr.warning > td {
  background-color: #fcf8e3;
}
.table tbody tr.info > td {
  background-color: #d9edf7;
}
.table-hover tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
.table-hover tbody tr.error:hover > td {
  background-color: #ebcccc;
}
.table-hover tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
.table-hover tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 8px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #4b4b4b;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: #fff;
  background-color: #000000;
  background-image: -moz-linear-gradient(top, #000, #000000);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(#000000));
  background-image: -webkit-linear-gradient(top, #000, #000000);
  background-image: -o-linear-gradient(top, #000, #000000);
  background-image: linear-gradient(to bottom, #000, #000000);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#ff000000', GradientType=0);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #000000;
  background-image: -moz-linear-gradient(top, #000, #000000);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(#000000));
  background-image: -webkit-linear-gradient(top, #000, #000000);
  background-image: -o-linear-gradient(top, #000, #000000);
  background-image: linear-gradient(to bottom, #000, #000000);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#ff000000', GradientType=0);
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #ccc;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #4b4b4b;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #ccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #4b4b4b;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.btn:active,
.btn.active {
  background-color: #cccccc \9;
}
.btn:first-child {
  *margin-left: 0;
}
.btn:hover,
.btn:focus {
  color: #4b4b4b;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}
.btn-mini {
  padding: 0 6px;
  font-size: 10.5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #cd0069;
  background-image: -moz-linear-gradient(top, #cd0084, #cd0040);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#cd0084), to(#cd0040));
  background-image: -webkit-linear-gradient(top, #cd0084, #cd0040);
  background-image: -o-linear-gradient(top, #cd0084, #cd0040);
  background-image: linear-gradient(to bottom, #cd0084, #cd0040);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffcd0084', endColorstr='#ffcd0040', GradientType=0);
  border-color: #cd0040 #cd0040 #810028;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #cd0040;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #fff;
  background-color: #cd0040;
  *background-color: #b40038;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #9a0030 \9;
}
.btn-warning {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #fff;
  background-color: #f89406;
  *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #bd362f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #fff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}
.btn-success {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #51a351;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
  *background-color: #499249;
}
.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}
.btn-info {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #fff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}
.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #3d3d3d;
  background-image: -moz-linear-gradient(top, #444, #333);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444), to(#333));
  background-image: -webkit-linear-gradient(top, #444, #333);
  background-image: -o-linear-gradient(top, #444, #333);
  background-image: linear-gradient(to bottom, #444, #333);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff333333', GradientType=0);
  border-color: #333 #333 #0d0d0d;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #333;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #fff;
  background-color: #333;
  *background-color: #262626;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #1a1a1a \9;
}
button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #000;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: #666;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: #4b4b4b;
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: .3em;
}
.btn-group:first-child {
  *margin-left: 0;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 9px;
  margin-bottom: 9px;
}
.btn-toolbar > .btn + .btn,
.btn-toolbar > .btn-group + .btn,
.btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-size: 14px;
}
.btn-group > .btn-mini {
  font-size: 10.5px;
}
.btn-group > .btn-small {
  font-size: 11.9px;
}
.btn-group > .btn-large {
  font-size: 17.5px;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #cd0040;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #333;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 8px;
}
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.btn-group-vertical > .btn:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.btn-group-vertical > .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical > .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 18px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.alert,
.alert h4 {
  color: #c09853;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 18px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-success h4 {
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.alert-danger h4,
.alert-error h4 {
  color: #b94a48;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-info h4 {
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.nav {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
.nav > li > a > img {
  max-width: none;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  color: #ccc;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #000;
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 8px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.nav-tabs,
.nav-pills {
  *zoom: 1;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: #eee #eee #ddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #666;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  color: #fff;
  background-color: #000;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
  border-color: #ddd;
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: #000;
  border-bottom-color: #000;
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle:focus .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}
.nav > .dropdown.active > a:hover,
.nav > .dropdown.active > a:focus {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret,
.nav li.dropdown.open a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover,
.tabs-stacked .open > a:focus {
  border-color: #ccc;
}
.tabbable {
  *zoom: 1;
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tab-content {
  overflow: auto;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eee #ddd #eee #eee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #fff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eee #eee #eee #ddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #fff;
}
.nav > .disabled > a {
  color: #ccc;
}
.nav > .disabled > a:hover,
.nav > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.navbar {
  overflow: visible;
  margin-bottom: 18px;
  *position: relative;
  *z-index: 2;
}
.navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  *zoom: 1;
}
.navbar-inner:before,
.navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-inner:after {
  clear: both;
}
.navbar .container {
  width: auto;
}
.nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.navbar .brand {
  float: left;
  display: block;
  padding: 11px 20px 11px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .brand:hover,
.navbar .brand:focus {
  text-decoration: none;
}
.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
  color: #777;
}
.navbar-link {
  color: #777;
}
.navbar-link:hover,
.navbar-link:focus {
  color: #4b4b4b;
}
.navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.navbar .btn,
.navbar .btn-group {
  margin-top: 5px;
}
.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn,
.navbar .input-prepend .btn-group,
.navbar .input-append .btn-group {
  margin-top: 0;
}
.navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}
.navbar-form:before,
.navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-form:after {
  clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: 5px;
}
.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}
.navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: rsa_sansregular, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.navbar-static-top .navbar-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.navbar-fixed-top {
  top: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.navbar-fixed-bottom {
  bottom: 0;
}
.navbar-fixed-bottom .navbar-inner {
  -webkit-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a {
  float: none;
  padding: 11px 15px 11px;
  color: #777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  background-color: transparent;
  color: #4b4b4b;
  text-decoration: none;
}
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: #666;
  text-decoration: none;
  background-color: #e5e5e5;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:focus,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: #fff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
  background-color: #cccccc \9;
}
.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.navbar .nav li.dropdown > a:hover .caret,
.navbar .nav li.dropdown > a:focus .caret {
  border-top-color: #4b4b4b;
  border-bottom-color: #4b4b4b;
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #666;
}
.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}
.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}
.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  border-color: #252525;
}
.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #ccc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .brand:hover,
.navbar-inverse .nav > li > a:hover,
.navbar-inverse .brand:focus,
.navbar-inverse .nav > li > a:focus {
  color: #fff;
}
.navbar-inverse .brand {
  color: #ccc;
}
.navbar-inverse .navbar-text {
  color: #ccc;
}
.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #fff;
}
.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #fff;
  background-color: #111111;
}
.navbar-inverse .navbar-link {
  color: #ccc;
}
.navbar-inverse .navbar-link:hover,
.navbar-inverse .navbar-link:focus {
  color: #fff;
}
.navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #fff;
}
.navbar-inverse .nav li.dropdown > a:hover .caret,
.navbar-inverse .nav li.dropdown > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #ccc;
  border-bottom-color: #ccc;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .navbar-search .search-query {
  color: #fff;
  background-color: #515151;
  border-color: #111111;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #4b4b4b;
  text-shadow: 0 1px 0 #fff;
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.navbar-inverse .btn-navbar {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
  background-image: -webkit-linear-gradient(top, #151515, #040404);
  background-image: -o-linear-gradient(top, #151515, #040404);
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-inverse .btn-navbar:hover,
.navbar-inverse .btn-navbar:focus,
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active,
.navbar-inverse .btn-navbar.disabled,
.navbar-inverse .btn-navbar[disabled] {
  color: #fff;
  background-color: #040404;
  *background-color: #000000;
}
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}
.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 18px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  text-shadow: 0 1px 0 #fff;
}
.breadcrumb > li > .divider {
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb > .active {
  color: #ccc;
}
.pagination {
  margin: 18px 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination ul > li {
  display: inline;
}
.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 18px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination ul > li > a:hover,
.pagination ul > li > a:focus,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}
.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #ccc;
  cursor: default;
}
.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover,
.pagination ul > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
  cursor: default;
}
.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pagination-large ul > li > a,
.pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 17.5px;
}
.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.pagination-small ul > li > a,
.pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 11.9px;
}
.pagination-mini ul > li > a,
.pagination-mini ul > li > span {
  padding: 0 6px;
  font-size: 10.5px;
}
.pager {
  margin: 18px 0;
  list-style: none;
  text-align: center;
  *zoom: 1;
}
.pager:before,
.pager:after {
  display: table;
  content: "";
  line-height: 0;
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #ccc;
  background-color: #fff;
  cursor: default;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin-left: -280px;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  /* IE6-7 */
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.modal.fade {
  -webkit-transition: opacity .3s linear, top .3s ease-out;
  -moz-transition: opacity .3s linear, top .3s ease-out;
  -o-transition: opacity .3s linear, top .3s ease-out;
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 10%;
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-header h3 {
  margin: 0;
  line-height: 30px;
}
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: inset 0 1px 0 #fff;
  -moz-box-shadow: inset 0 1px 0 #fff;
  box-shadow: inset 0 1px 0 #fff;
  *zoom: 1;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.popover-title:empty {
  display: none;
}
.popover-content {
  padding: 9px 14px;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow {
  border-width: 11px;
}
.popover .arrow:after {
  border-width: 10px;
  content: "";
}
.popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right .arrow:after {
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}
.popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left .arrow:after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}
.thumbnails {
  margin-left: -20px;
  list-style: none;
  *zoom: 1;
}
.thumbnails:before,
.thumbnails:after {
  display: table;
  content: "";
  line-height: 0;
}
.thumbnails:after {
  clear: both;
}
.row-fluid .thumbnails {
  margin-left: 0;
}
.thumbnails > li {
  float: left;
  margin-bottom: 18px;
  margin-left: 20px;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 18px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.thumbnail:hover,
a.thumbnail:focus {
  border-color: #000;
  -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: #666;
}
.media,
.media-body {
  overflow: hidden;
  *overflow: visible;
  zoom: 1;
}
.media,
.media .media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}
.media-object {
  display: block;
}
.media-heading {
  margin: 0 0 5px;
}
.media > .pull-left {
  margin-right: 10px;
}
.media > .pull-right {
  margin-left: 10px;
}
.media-list {
  margin-left: 0;
  list-style: none;
}
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ccc;
}
.label {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.label:empty,
.badge:empty {
  display: none;
}
a.label:hover,
a.label:focus,
a.badge:hover,
a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label-important,
.badge-important {
  background-color: #b94a48;
}
.label-important[href],
.badge-important[href] {
  background-color: #953b39;
}
.label-warning,
.badge-warning {
  background-color: #f89406;
}
.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
.label-success,
.badge-success {
  background-color: #468847;
}
.label-success[href],
.badge-success[href] {
  background-color: #356635;
}
.label-info,
.badge-info {
  background-color: #3a87ad;
}
.label-info[href],
.badge-info[href] {
  background-color: #2d6987;
}
.label-inverse,
.badge-inverse {
  background-color: #4b4b4b;
}
.label-inverse[href],
.badge-inverse[href] {
  background-color: #323232;
}
.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-mini .label,
.btn-mini .badge {
  top: 0;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  overflow: hidden;
  height: 18px;
  margin-bottom: 18px;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.progress .bar {
  width: 0%;
  height: 100%;
  color: #fff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
}
.progress-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-danger .bar,
.progress .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}
.progress-danger.progress-striped .bar,
.progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-success .bar,
.progress .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}
.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar,
.progress .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}
.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-warning .bar,
.progress .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}
.progress-warning.progress-striped .bar,
.progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.accordion {
  margin-bottom: 18px;
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
.carousel {
  position: relative;
  margin-bottom: 18px;
  line-height: 1;
}
.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  line-height: 1;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background: #333;
  border: 3px solid #fff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.carousel-control.right {
  left: auto;
  right: 15px;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-indicators {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  margin: 0;
  list-style: none;
}
.carousel-indicators li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  text-indent: -999px;
  background-color: #ccc;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #4b4b4b;
  background: rgba(0, 0, 0, 0.75);
}
.carousel-caption h4,
.carousel-caption p {
  color: #fff;
  line-height: 18px;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 200;
  line-height: 27px;
  color: inherit;
  background-color: #eee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  color: inherit;
  letter-spacing: -1px;
}
.hero-unit li {
  line-height: 27px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.hidden {
  display: none;
  visibility: hidden;
}
.visible-phone {
  display: none !important;
}
.visible-tablet {
  display: none !important;
}
.hidden-desktop {
  display: none !important;
}
.visible-desktop {
  display: inherit !important;
}
@media (min-width: 768px) and (max-width: 979px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important ;
  }
  .visible-tablet {
    display: inherit !important;
  }
  .hidden-tablet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-phone {
    display: inherit !important;
  }
  .hidden-phone {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: inherit !important;
  }
  .hidden-print {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .row {
    margin-left: -30px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 30px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 1170px;
  }
  .span12 {
    width: 1170px;
  }
  .span11 {
    width: 1070px;
  }
  .span10 {
    width: 970px;
  }
  .span9 {
    width: 870px;
  }
  .span8 {
    width: 770px;
  }
  .span7 {
    width: 670px;
  }
  .span6 {
    width: 570px;
  }
  .span5 {
    width: 470px;
  }
  .span4 {
    width: 370px;
  }
  .span3 {
    width: 270px;
  }
  .span2 {
    width: 170px;
  }
  .span1 {
    width: 70px;
  }
  .offset12 {
    margin-left: 1230px;
  }
  .offset11 {
    margin-left: 1130px;
  }
  .offset10 {
    margin-left: 1030px;
  }
  .offset9 {
    margin-left: 930px;
  }
  .offset8 {
    margin-left: 830px;
  }
  .offset7 {
    margin-left: 730px;
  }
  .offset6 {
    margin-left: 630px;
  }
  .offset5 {
    margin-left: 530px;
  }
  .offset4 {
    margin-left: 430px;
  }
  .offset3 {
    margin-left: 330px;
  }
  .offset2 {
    margin-left: 230px;
  }
  .offset1 {
    margin-left: 130px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.56410256%;
    *margin-left: 2.51091107%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.56410256%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851%;
  }
  .row-fluid .span11 {
    width: 91.45299145%;
    *width: 91.39979996%;
  }
  .row-fluid .span10 {
    width: 82.90598291%;
    *width: 82.85279142%;
  }
  .row-fluid .span9 {
    width: 74.35897436%;
    *width: 74.30578287%;
  }
  .row-fluid .span8 {
    width: 65.81196581%;
    *width: 65.75877432%;
  }
  .row-fluid .span7 {
    width: 57.26495726%;
    *width: 57.21176578%;
  }
  .row-fluid .span6 {
    width: 48.71794872%;
    *width: 48.66475723%;
  }
  .row-fluid .span5 {
    width: 40.17094017%;
    *width: 40.11774868%;
  }
  .row-fluid .span4 {
    width: 31.62393162%;
    *width: 31.57074013%;
  }
  .row-fluid .span3 {
    width: 23.07692308%;
    *width: 23.02373159%;
  }
  .row-fluid .span2 {
    width: 14.52991453%;
    *width: 14.47672304%;
  }
  .row-fluid .span1 {
    width: 5.98290598%;
    *width: 5.92971449%;
  }
  .row-fluid .offset12 {
    margin-left: 105.12820513%;
    *margin-left: 105.02182215%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.56410256%;
    *margin-left: 102.45771959%;
  }
  .row-fluid .offset11 {
    margin-left: 96.58119658%;
    *margin-left: 96.4748136%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 94.01709402%;
    *margin-left: 93.91071104%;
  }
  .row-fluid .offset10 {
    margin-left: 88.03418803%;
    *margin-left: 87.92780506%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.47008547%;
    *margin-left: 85.36370249%;
  }
  .row-fluid .offset9 {
    margin-left: 79.48717949%;
    *margin-left: 79.38079651%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.92307692%;
    *margin-left: 76.81669394%;
  }
  .row-fluid .offset8 {
    margin-left: 70.94017094%;
    *margin-left: 70.83378796%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.37606838%;
    *margin-left: 68.2696854%;
  }
  .row-fluid .offset7 {
    margin-left: 62.39316239%;
    *margin-left: 62.28677941%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.82905983%;
    *margin-left: 59.72267685%;
  }
  .row-fluid .offset6 {
    margin-left: 53.84615385%;
    *margin-left: 53.73977087%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.28205128%;
    *margin-left: 51.1756683%;
  }
  .row-fluid .offset5 {
    margin-left: 45.2991453%;
    *margin-left: 45.19276232%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.73504274%;
    *margin-left: 42.62865976%;
  }
  .row-fluid .offset4 {
    margin-left: 36.75213675%;
    *margin-left: 36.64575377%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.18803419%;
    *margin-left: 34.08165121%;
  }
  .row-fluid .offset3 {
    margin-left: 28.20512821%;
    *margin-left: 28.09874523%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.64102564%;
    *margin-left: 25.53464266%;
  }
  .row-fluid .offset2 {
    margin-left: 19.65811966%;
    *margin-left: 19.55173668%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.09401709%;
    *margin-left: 16.98763412%;
  }
  .row-fluid .offset1 {
    margin-left: 11.11111111%;
    *margin-left: 11.00472813%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.54700855%;
    *margin-left: 8.44062557%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 30px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 1156px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 1056px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 956px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 856px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 756px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 656px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 556px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 456px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 356px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 256px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 156px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 56px;
  }
  .thumbnails {
    margin-left: -30px;
  }
  .thumbnails > li {
    margin-left: 30px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -20px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 724px;
  }
  .span12 {
    width: 724px;
  }
  .span11 {
    width: 662px;
  }
  .span10 {
    width: 600px;
  }
  .span9 {
    width: 538px;
  }
  .span8 {
    width: 476px;
  }
  .span7 {
    width: 414px;
  }
  .span6 {
    width: 352px;
  }
  .span5 {
    width: 290px;
  }
  .span4 {
    width: 228px;
  }
  .span3 {
    width: 166px;
  }
  .span2 {
    width: 104px;
  }
  .span1 {
    width: 42px;
  }
  .offset12 {
    margin-left: 764px;
  }
  .offset11 {
    margin-left: 702px;
  }
  .offset10 {
    margin-left: 640px;
  }
  .offset9 {
    margin-left: 578px;
  }
  .offset8 {
    margin-left: 516px;
  }
  .offset7 {
    margin-left: 454px;
  }
  .offset6 {
    margin-left: 392px;
  }
  .offset5 {
    margin-left: 330px;
  }
  .offset4 {
    margin-left: 268px;
  }
  .offset3 {
    margin-left: 206px;
  }
  .offset2 {
    margin-left: 144px;
  }
  .offset1 {
    margin-left: 82px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.76243094%;
    *margin-left: 2.70923945%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.76243094%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851%;
  }
  .row-fluid .span11 {
    width: 91.43646409%;
    *width: 91.3832726%;
  }
  .row-fluid .span10 {
    width: 82.87292818%;
    *width: 82.81973669%;
  }
  .row-fluid .span9 {
    width: 74.30939227%;
    *width: 74.25620078%;
  }
  .row-fluid .span8 {
    width: 65.74585635%;
    *width: 65.69266486%;
  }
  .row-fluid .span7 {
    width: 57.18232044%;
    *width: 57.12912895%;
  }
  .row-fluid .span6 {
    width: 48.61878453%;
    *width: 48.56559304%;
  }
  .row-fluid .span5 {
    width: 40.05524862%;
    *width: 40.00205713%;
  }
  .row-fluid .span4 {
    width: 31.49171271%;
    *width: 31.43852122%;
  }
  .row-fluid .span3 {
    width: 22.9281768%;
    *width: 22.87498531%;
  }
  .row-fluid .span2 {
    width: 14.36464088%;
    *width: 14.31144939%;
  }
  .row-fluid .span1 {
    width: 5.80110497%;
    *width: 5.74791348%;
  }
  .row-fluid .offset12 {
    margin-left: 105.52486188%;
    *margin-left: 105.4184789%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.76243094%;
    *margin-left: 102.65604796%;
  }
  .row-fluid .offset11 {
    margin-left: 96.96132597%;
    *margin-left: 96.85494299%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 94.19889503%;
    *margin-left: 94.09251205%;
  }
  .row-fluid .offset10 {
    margin-left: 88.39779006%;
    *margin-left: 88.29140708%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.63535912%;
    *margin-left: 85.52897614%;
  }
  .row-fluid .offset9 {
    margin-left: 79.83425414%;
    *margin-left: 79.72787116%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 77.0718232%;
    *margin-left: 76.96544023%;
  }
  .row-fluid .offset8 {
    margin-left: 71.27071823%;
    *margin-left: 71.16433525%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.50828729%;
    *margin-left: 68.40190431%;
  }
  .row-fluid .offset7 {
    margin-left: 62.70718232%;
    *margin-left: 62.60079934%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.94475138%;
    *margin-left: 59.8383684%;
  }
  .row-fluid .offset6 {
    margin-left: 54.14364641%;
    *margin-left: 54.03726343%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.38121547%;
    *margin-left: 51.27483249%;
  }
  .row-fluid .offset5 {
    margin-left: 45.5801105%;
    *margin-left: 45.47372752%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.81767956%;
    *margin-left: 42.71129658%;
  }
  .row-fluid .offset4 {
    margin-left: 37.01657459%;
    *margin-left: 36.91019161%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.25414365%;
    *margin-left: 34.14776067%;
  }
  .row-fluid .offset3 {
    margin-left: 28.45303867%;
    *margin-left: 28.3466557%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.69060773%;
    *margin-left: 25.58422476%;
  }
  .row-fluid .offset2 {
    margin-left: 19.88950276%;
    *margin-left: 19.78311978%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.12707182%;
    *margin-left: 17.02068884%;
  }
  .row-fluid .offset1 {
    margin-left: 11.32596685%;
    *margin-left: 11.21958387%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.56353591%;
    *margin-left: 8.45715293%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 710px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 648px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 586px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 524px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 462px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 400px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 338px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 276px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 214px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 152px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 90px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 28px;
  }
}
@media (max-width: 767px) {
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .container-fluid {
    padding: 0;
  }
  .dl-horizontal dt {
    float: none;
    clear: none;
    width: auto;
    text-align: left;
  }
  .dl-horizontal dd {
    margin-left: 0;
  }
  .container {
    width: auto;
  }
  .row-fluid {
    width: 100%;
  }
  .row,
  .thumbnails {
    margin-left: 0;
  }
  .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  [class*="span"],
  .uneditable-input[class*="span"],
  .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .span12,
  .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row-fluid [class*="offset"]:first-child {
    margin-left: 0;
  }
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    margin: 0;
  }
  .modal.fade {
    top: -100px;
  }
  .modal.fade.in {
    top: 20px;
  }
}
@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .page-header h1 small {
    display: block;
    line-height: 18px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }
  .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .form-horizontal .control-list {
    padding-top: 0;
  }
  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }
  .media .pull-left,
  .media .pull-right {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  .media-object {
    margin-right: 0;
    margin-left: 0;
  }
  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .carousel-caption {
    position: static;
  }
}
@media (max-width: 979px) {
  body {
    padding-top: 0;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-top {
    margin-bottom: 18px;
  }
  .navbar-fixed-bottom {
    margin-top: 18px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 9px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #ccc;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 9px 15px;
    margin: 9px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 979px + 1) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}
@font-face {
  font-family: 'rsa-icons';
  src: url('../fonts/rsa-icons.eot?97951932');
  src: url('../fonts/rsa-icons.eot?97951932#iefix') format('embedded-opentype'), url('../fonts/rsa-icons.woff?97951932') format('woff'), url('../fonts/rsa-icons.ttf?97951932') format('truetype'), url('../fonts/rsa-icons.svg?97951932#rsa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'chat-rsa';
  src: url('../fonts/new-icons/chat-icon.eot');
  src: url('../fonts/new-icons/chat-icon.eot') format('embedded-opentype'), url('../fonts/new-icons/chat-icon.ttf') format('truetype'), url('../fonts/new-icons/chat-icon.woff') format('woff'), url('../fonts/new-icons/chat-icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'rsa-icons';
    src: url('../font/rsa-icons.svg?97951932#rsa-icons') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-volume-low:before {
  content: '\e800';
}
/* '' */
.icon-volume-off:before {
  content: '\e801';
}
/* '' */
.icon-ship:before {
  content: '\e802';
}
/* '' */
.icon-mic:before {
  content: '\e803';
}
/* '' */
.icon-truck:before {
  content: '\e804';
}
/* '' */
.icon-calendar:before {
  content: '\e805';
}
/* '' */
.icon-basket:before {
  content: '\e806';
}
/* '' */
.icon-turbine:before {
  content: '\e807';
}
/* '' */
.icon-wrench:before {
  content: '\e808';
}
/* '' */
.icon-umbrella:before {
  content: '\e809';
}
/* '' */
.icon-cog:before {
  content: '\e80a';
}
/* '' */
.icon-compass:before {
  content: '\e80b';
}
/* '' */
.icon-menu:before {
  content: '\e80c';
}
/* '' */
.icon-wheat:before {
  content: '\e80d';
}
/* '' */
.icon-map:before {
  content: '\e80e';
}
/* '' */
.icon-doc-text:before {
  content: '\e80f';
}
/* '' */
.icon-doc-add:before {
  content: '\e810';
}
/* '' */
.icon-doc-remove:before {
  content: '\e811';
}
/* '' */
.icon-news:before {
  content: '\e812';
}
/* '' */
.icon-folder:before {
  content: '\e813';
}
/* '' */
.icon-folder-add:before {
  content: '\e814';
}
/* '' */
.icon-folder-delete:before {
  content: '\e815';
}
/* '' */
.icon-archive:before {
  content: '\e816';
}
/* '' */
.icon-box:before {
  content: '\e817';
}
/* '' */
.icon-rss:before {
  content: '\e818';
}
/* '' */
.icon-woman:before {
  content: '\e819';
}
/* '' */
.icon-phone:before {
  content: '\e81a';
}
/* '' */
.icon-location:before {
  content: '\e81b';
}
/* '' */
.icon-address:before {
  content: '\e81c';
}
/* '' */
.icon-vcard:before {
  content: '\e81d';
}
/* '' */
.icon-chat:before {
  content: '\e81e';
}
/* '' */
.icon-comment:before {
  content: '\e81f';
}
/* '' */
.icon-print:before {
  content: '\e820';
}
/* '' */
.icon-edit:before {
  content: '\e821';
}
/* '' */
.icon-feather:before {
  content: '\e822';
}
/* '' */
.icon-pen:before {
  content: '\e823';
}
/* '' */
.icon-pencil:before {
  content: '\e824';
}
/* '' */
.icon-export:before {
  content: '\e825';
}
/* '' */
.icon-eye:before {
  content: '\e826';
}
/* '' */
.icon-tag:before {
  content: '\e827';
}
/* '' */
.icon-tags:before {
  content: '\e828';
}
/* '' */
.icon-bookmark:before {
  content: '\e829';
}
/* '' */
.icon-flag:before {
  content: '\e82a';
}
/* '' */
.icon-thumbs-up:before {
  content: '\e82b';
}
/* '' */
.icon-thumbs-down:before {
  content: '\e82c';
}
/* '' */
.icon-code:before {
  content: '\e82d';
}
/* '' */
.icon-pin:before {
  content: '\e82e';
}
/* '' */
.icon-lock-open:before {
  content: '\e82f';
}
/* '' */
.icon-lock:before {
  content: '\e830';
}
/* '' */
.icon-search:before {
  content: '\e831';
}
/* '' */
.icon-mail:before {
  content: '\e832';
}
/* '' */
.icon-heart:before {
  content: '\e833';
}
/* '' */
.icon-star:before {
  content: '\e834';
}
/* '' */
.icon-user:before {
  content: '\e835';
}
/* '' */
.icon-users:before {
  content: '\e836';
}
/* '' */
.icon-camera:before {
  content: '\e837';
}
/* '' */
.icon-th:before {
  content: '\e838';
}
/* '' */
.icon-th-large:before {
  content: '\e839';
}
/* '' */
.icon-th-list:before {
  content: '\e83a';
}
/* '' */
.icon-home:before {
  content: '\e83b';
}
/* '' */
.icon-link:before {
  content: '\e83c';
}
/* '' */
.icon-attach:before {
  content: '\e83d';
}
/* '' */
.icon-picture:before {
  content: '\e83e';
}
/* '' */
.icon-minus:before {
  content: '\e83f';
}
/* '' */
.icon-user-add:before {
  content: '\e840';
}
/* '' */
.icon-user-delete:before {
  content: '\e841';
}
/* '' */
.icon-video:before {
  content: '\e842';
}
/* '' */
.icon-videocam:before {
  content: '\e843';
}
/* '' */
.icon-cancel-alt:before {
  content: '\e844';
}
/* '' */
.icon-cancel-circled:before {
  content: '\e845';
}
/* '' */
.icon-plus:before {
  content: '\e846';
}
/* '' */
.icon-cat:before {
  content: '\e847';
}
/* '' */
.icon-facebook:before {
  content: '\e848';
}
/* '' */
.icon-flickr-circled:before {
  content: '\e849';
}
/* '' */
.icon-flickr:before {
  content: '\e84a';
}
/* '' */
.icon-github-circled:before {
  content: '\e84b';
}
/* '' */
.icon-github:before {
  content: '\e84c';
}
/* '' */
.icon-anchor:before {
  content: '\e84d';
}
/* '' */
.icon-scissors:before {
  content: '\e84e';
}
/* '' */
.icon-balloon:before {
  content: '\e84f';
}
/* '' */
.icon-certificate:before {
  content: '\e850';
}
/* '' */
.icon-disabled:before {
  content: '\e851';
}
/* '' */
.icon-flow-split:before {
  content: '\e852';
}
/* '' */
.icon-flow-merge:before {
  content: '\e853';
}
/* '' */
.icon-flow-parallel:before {
  content: '\e854';
}
/* '' */
.icon-flow-cross:before {
  content: '\e855';
}
/* '' */
.icon-drizzle:before {
  content: '\e856';
}
/* '' */
.icon-cloud-sun:before {
  content: '\e857';
}
/* '' */
.icon-rain:before {
  content: '\e858';
}
/* '' */
.icon-waves:before {
  content: '\e859';
}
/* '' */
.icon-wrench-alt:before {
  content: '\e85a';
}
/* '' */
.icon-wifi:before {
  content: '\e85b';
}
/* '' */
.icon-target:before {
  content: '\e85c';
}
/* '' */
.icon-plug:before {
  content: '\e85d';
}
/* '' */
.icon-record-alt:before {
  content: '\e85e';
}
/* '' */
.icon-bat2:before {
  content: '\e85f';
}
/* '' */
.icon-bat3:before {
  content: '\e860';
}
/* '' */
.icon-bat4:before {
  content: '\e861';
}
/* '' */
.icon-bat-charge:before {
  content: '\e862';
}
/* '' */
.icon-play:before {
  content: '\e863';
}
/* '' */
.icon-padlock:before {
  content: '\e864';
}
/* '' */
.icon-resize-full:before {
  content: '\e865';
}
/* '' */
.icon-resize-normal:before {
  content: '\e866';
}
/* '' */
.icon-wristwatch:before {
  content: '\e867';
}
/* '' */
.icon-clock:before {
  content: '\e868';
}
/* '' */
.icon-headphones:before {
  content: '\e869';
}
/* '' */
.icon-volume-high:before {
  content: '\e86a';
}
/* '' */
.icon-volume-middle:before {
  content: '\e86b';
}
/* '' */
.icon-direction:before {
  content: '\e86c';
}
/* '' */
.icon-trash:before {
  content: '\e86d';
}
/* '' */
.icon-doc:before {
  content: '\e86e';
}
/* '' */
.icon-contacts:before {
  content: '\e86f';
}
/* '' */
.icon-warning-empty:before {
  content: '\e870';
}
/* '' */
.icon-attention:before {
  content: '\e871';
}
/* '' */
.icon-bell:before {
  content: '\e872';
}
/* '' */
.icon-chat-alt:before {
  content: '\e873';
}
/* '' */
.icon-download:before {
  content: '\e874';
}
/* '' */
.icon-upload:before {
  content: '\e875';
}
/* '' */
.icon-upload-cloud:before {
  content: '\e876';
}
/* '' */
.icon-reply:before {
  content: '\e877';
}
/* '' */
.icon-forward:before {
  content: '\e878';
}
/* '' */
.icon-ok:before {
  content: '\e879';
}
/* '' */
.icon-cancel:before {
  content: '\e87a';
}
/* '' */
.icon-divide:before {
  content: '\e87b';
}
/* '' */
.icon-eq:before {
  content: '\e87c';
}
/* '' */
.icon-info:before {
  content: '\e87d';
}
/* '' */
.icon-music:before {
  content: '\e87e';
}
/* '' */
.icon-champagne:before {
  content: '\e87f';
}
/* '' */
.icon-vimeo-circled:before {
  content: '\e880';
}
/* '' */
.icon-car:before {
  content: '\e881';
}
/* '' */
.icon-twitter-circled:before {
  content: '\e882';
}
/* '' */
.icon-tumbler:before {
  content: '\e883';
}
/* '' */
.icon-tumbler-circled:before {
  content: '\e884';
}
/* '' */
.icon-bridge:before {
  content: '\e885';
}
/* '' */
.icon-skype:before {
  content: '\e886';
}
/* '' */
.icon-looped-square:before {
  content: '\e887';
}
/* '' */
.icon-sort-alphabet:before {
  content: '\e888';
}
/* '' */
.icon-sort-numeric:before {
  content: '\e889';
}
/* '' */
.icon-dribbble-circled:before {
  content: '\e88a';
}
/* '' */
.icon-dribbble:before {
  content: '\e88b';
}
/* '' */
.icon-facebook-circled:before {
  content: '\e88c';
}
/* '' */
.icon-lastfm-circled:before {
  content: '\e88d';
}
/* '' */
.icon-lastfm:before {
  content: '\e88e';
}
/* '' */
.icon-linkedin-circled:before {
  content: '\e88f';
}
/* '' */
.icon-linkedin:before {
  content: '\e890';
}
/* '' */
.icon-pinterest-circled:before {
  content: '\e891';
}
/* '' */
.icon-pinterest:before {
  content: '\e892';
}
/* '' */
.icon-at-circled:before {
  content: '\e893';
}
/* '' */
.icon-at:before {
  content: '\e894';
}
/* '' */
.icon-pi:before {
  content: '\e895';
}
/* '' */
.icon-infinity:before {
  content: '\e896';
}
/* '' */
.icon-calculator:before {
  content: '\e897';
}
/* '' */
.icon-tree:before {
  content: '\e898';
}
/* '' */
.icon-boat:before {
  content: '\e899';
}
/* '' */
.icon-puzzle:before {
  content: '\e89a';
}
/* '' */
.icon-hazard:before {
  content: '\e89b';
}
/* '' */
.icon-gift:before {
  content: '\e89c';
}
/* '' */
.icon-temperatire:before {
  content: '\e89d';
}
/* '' */
.icon-chart:before {
  content: '\e89e';
}
/* '' */
.icon-chart-alt:before {
  content: '\e89f';
}
/* '' */
.icon-city:before {
  content: '\e8a0';
}
/* '' */
.icon-chart-bar:before {
  content: '\e8a1';
}
/* '' */
.icon-coat-hanger:before {
  content: '\e8a2';
}
/* '' */
.icon-chart-pie:before {
  content: '\e8a3';
}
/* '' */
.icon-coffee:before {
  content: '\e8a4';
}
/* '' */
.icon-ticket:before {
  content: '\e8a5';
}
/* '' */
.icon-credit-card:before {
  content: '\e8a6';
}
/* '' */
.icon-clipboard:before {
  content: '\e8a7';
}
/* '' */
.icon-database:before {
  content: '\e8a8';
}
/* '' */
.icon-key:before {
  content: '\e8a9';
}
/* '' */
.icon-check:before {
  content: '\e8aa';
}
/* '' */
.icon-check-alt:before {
  content: '\e8ab';
}
/* '' */
.icon-dog:before {
  content: '\e8ac';
}
/* '' */
.icon-power:before {
  content: '\e8ad';
}
/* '' */
.icon-pipette:before {
  content: '\e8ae';
}
/* '' */
.icon-brush:before {
  content: '\e8af';
}
/* '' */
.icon-briefcase:before {
  content: '\e8b0';
}
/* '' */
.icon-lifebuoy:before {
  content: '\e8b1';
}
/* '' */
.icon-leaf:before {
  content: '\e8b2';
}
/* '' */
.icon-factory:before {
  content: '\e8b3';
}
/* '' */
.icon-plane-alt:before {
  content: '\e8b4';
}
/* '' */
.icon-wind:before {
  content: '\e8b5';
}
/* '' */
.icon-cloud-wind:before {
  content: '\e8b6';
}
/* '' */
.icon-cloud-flash:before {
  content: '\e8b7';
}
/* '' */
.icon-snow:before {
  content: '\e8b8';
}
/* '' */
.icon-moon:before {
  content: '\e8b9';
}
/* '' */
.icon-handshake:before {
  content: '\e8ba';
}
/* '' */
.icon-flash:before {
  content: '\e8bb';
}
/* '' */
.icon-cloud:before {
  content: '\e8bc';
}
/* '' */
.icon-lightbulb-alt:before {
  content: '\e8bd';
}
/* '' */
.icon-sun:before {
  content: '\e8be';
}
/* '' */
.icon-shower:before {
  content: '\e8bf';
}
/* '' */
.icon-globe-alt:before {
  content: '\e8c0';
}
/* '' */
.icon-signpost:before {
  content: '\e8c1';
}
/* '' */
.icon-globe:before {
  content: '\e8c2';
}
/* '' */
.icon-contrast:before {
  content: '\e8c3';
}
/* '' */
.icon-mobile:before {
  content: '\e8c4';
}
/* '' */
.icon-tablet:before {
  content: '\e8c5';
}
/* '' */
.icon-laptop:before {
  content: '\e8c6';
}
/* '' */
.icon-desktop:before {
  content: '\e8c7';
}
/* '' */
.icon-stop:before {
  content: '\e8c8';
}
/* '' */
.icon-speech-bubbles:before {
  content: '\e8c9';
}
/* '' */
.icon-pause:before {
  content: '\e8ca';
}
/* '' */
.icon-fast-fw:before {
  content: '\e8cb';
}
/* '' */
.icon-sun-alt:before {
  content: '\e8cc';
}
/* '' */
.icon-rewind:before {
  content: '\e8cd';
}
/* '' */
.icon-train:before {
  content: '\e8ce';
}
/* '' */
.icon-record:before {
  content: '\e8cf';
}
/* '' */
.icon-trophy:before {
  content: '\e8d0';
}
/* '' */
.icon-eject:before {
  content: '\e8d1';
}
/* '' */
.icon-eject-alt:before {
  content: '\e8d2';
}
/* '' */
.icon-hard-hat:before {
  content: '\e8d3';
}
/* '' */
.icon-bat1:before {
  content: '\e8d4';
}
/* '' */
.icon-shuffle:before {
  content: '\e8d5';
}
/* '' */
.icon-loop-alt:before {
  content: '\e8d6';
}
/* '' */
.icon-loop:before {
  content: '\e8d7';
}
/* '' */
.icon-arrows-cw:before {
  content: '\e8d8';
}
/* '' */
.icon-cw:before {
  content: '\e8d9';
}
/* '' */
.icon-up-small:before {
  content: '\e8da';
}
/* '' */
.icon-right-small:before {
  content: '\e8db';
}
/* '' */
.icon-left-small:before {
  content: '\e8dc';
}
/* '' */
.icon-down-small:before {
  content: '\e8dd';
}
/* '' */
.icon-up:before {
  content: '\e8de';
}
/* '' */
.icon-right:before {
  content: '\e8df';
}
/* '' */
.icon-left:before {
  content: '\e8e0';
}
/* '' */
.icon-down:before {
  content: '\e8e1';
}
/* '' */
.icon-man:before {
  content: '\e8e2';
}
/* '' */
.icon-block:before {
  content: '\e8e3';
}
/* '' */
.icon-move:before {
  content: '\e8e4';
}
/* '' */
.icon-popup:before {
  content: '\e8e5';
}
/* '' */
.icon-zoom-in:before {
  content: '\e8e6';
}
/* '' */
.icon-zoom-out:before {
  content: '\e8e7';
}
/* '' */
.icon-left-open:before {
  content: '\e8e8';
}
/* '' */
.icon-plane:before {
  content: '\e8e9';
}
/* '' */
.icon-right-open:before {
  content: '\e8ea';
}
/* '' */
.icon-recycling:before {
  content: '\e8eb';
}
/* '' */
.icon-scales:before {
  content: '\e8ec';
}
/* '' */
.icon-shield:before {
  content: '\e8ed';
}
/* '' */
.icon-lightbulb:before {
  content: '\e8ee';
}
/* '' */
.icon-stopwatch:before {
  content: '\e8ef';
}
/* '' */
.icon-cornering:before {
  content: '\e8f0';
}
/* '' */
.icon-alarm-clock:before {
  content: '\e8f1';
}
/* '' */
.icon-acceleration:before {
  content: '\e8f2';
}
/* '' */
.icon-save-money:before {
  content: '\e8f3';
}
/* '' */
.icon-easy:before {
  content: '\e8f4';
}
/* '' */
.icon-safe:before {
  content: '\e8f5';
}
/* '' */
.icon-right-open-1:before {
  content: '\e8f6';
}
/* '' */
.icon-left-open-1:before {
  content: '\e8f7';
}
/* '' */
html.overlay-open .navbar-fixed-top {
  z-index: 400;
}
html.js fieldset.collapsed {
  height: auto;
}
html.js input.form-autocomplete {
  background-position: 100% 8px;
  /* LTR */
  background-repeat: no-repeat;
}
html.js input.throbbing {
  background-position: 100% -13px;
  /* LTR */
}
body {
  border-top: #cd0084 5px solid;
  padding-top: 40px;
}
body,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  line-height: 1.25em;
  font-family: rsa_sansregular;
}
@media (max-width: 767px) {
  body {
    position: relative;
  }
}
body.toolbar .navbar-fixed-top {
  top: 30px;
}
body.toolbar-drawer .navbar-fixed-top {
  top: 65px;
}
body.admin-expanded.admin-vertical.admin-nw .navbar,
body.admin-expanded.admin-vertical.admin-sw .navbar {
  margin-left: 260px;
}
body div#admin-toolbar {
  z-index: 500;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  body.toolbar {
    padding-top: 94px !important;
  }
  body.toolbar-drawer {
    padding-top: 129px !important;
  }
}
body #admin-menu {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1100;
}
body #admin-menu .dropdown li {
  line-height: normal;
}
html body.admin-menu {
  margin-top: 28px !important;
}
body.admin-menu .navbar-fixed-top {
  top: 28px;
}
.footer {
  margin-top: 45px;
  padding: 35px 0 36px;
  border-top: 1px solid #E5E5E5;
}
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}
.navbar .logo {
  margin-top: 5px;
  margin-right: 10px;
}
#site-name {
  line-height: 1;
  margin: 0;
}
.page-header {
  margin-top: 0;
}
.block h2.block-title {
  margin-top: 0;
}
form#search-block-form {
  margin: 0;
}
.navbar #block-search-form {
  float: right;
  margin: 5px 0 5px 5px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 979px) {
  .navbar #block-search-form {
    float: none;
  }
}
fieldset.search-advanced {
  padding-bottom: 1.5em;
  margin-top: 1em;
}
.navbar-search .control-group {
  margin-bottom: 0px;
}
ul.action-links {
  margin-bottom: 2em;
}
ul.action-links li {
  display: inline;
  padding-right: 1.5em;
}
ul.action-links [class^="icon-"],
ul.action-links [class*=" icon-"] {
  padding-right: 0.5em;
}
input,
textarea,
select,
.uneditable-input {
  max-width: 100%;
  width: auto;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
}
input.error {
  color: #b94a48;
  border-color: #eed3d7;
}
.form-type-checkbox input,
.form-type-radio input {
  float: left;
}
.form-actions {
  clear: both;
}
.resizable-textarea textarea {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.help-block,
.control-group .help-inline {
  color: #ccc;
  font-size: 12px;
  margin: -8px 0 10px;
  padding: 0 3px;
}
.description + .help-block,
.description + .control-group .help-inline,
.checkbox + .help-block,
.checkbox + .control-group .help-inline,
.form-type-checkbox + .help-block,
.form-type-checkbox + .control-group .help-inline,
.form-type-radio + .help-block,
.form-type-radio + .control-group .help-inline,
.form-file + .help-block,
.form-file + .control-group .help-inline,
.resizable-textarea + .help-block,
.resizable-textarea + .control-group .help-inline {
  margin-top: 0;
}
.input-append {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 40px;
  width: 100%;
}
.input-append input,
.input-append textarea,
.input-append select,
.input-append .uneditable-input {
  width: 100% !important;
}
.container-inline div,
.container-inline label {
  display: block;
}
div.error,
table tr.error {
  background-color: #f2dede;
  color: #b94a48;
}
.control-group.error {
  background: none;
}
.control-group.error label,
.control-group.error .control-label {
  color: #b94a48;
  font-weight: 600;
}
.control-group.error input,
.control-group.error textarea,
.control-group.error select,
.control-group.error .uneditable-input {
  color: #b94a48;
  border: 1px solid #ccc;
}
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #ccc;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.vertical-tabs .form-type-textfield input {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  width: auto;
}
.vertical-tabs .form-item {
  margin: 1em 0;
}
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}
.password-strength {
  width: 17em;
  float: right;
  /* LTR */
  margin-top: 1.4em;
}
.password-strength-title {
  display: inline;
}
.password-strength-text {
  float: right;
  /* LTR */
  font-weight: bold;
}
.password-indicator {
  background-color: #e9e9e9;
  height: 0.3em;
  width: 100%;
}
.password-indicator div {
  height: 100%;
  width: 0%;
  background-color: #46a546;
}
input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em;
}
div.password-confirm {
  float: right;
  /* LTR */
  margin-top: 1.5em;
  visibility: hidden;
  width: 17em;
}
div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4;
}
div.password-suggestions ul {
  margin-bottom: 0;
}
.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  width: 36.3em;
}
.progress-wrapper .progress {
  margin-bottom: 10px;
}
.progress-wrapper .progress .bar {
  border: 0 none;
  margin: 0;
}
.pagination ul > li > a.progress-disabled {
  float: left;
}
.pagination ul > li > a .throbber {
  margin: 0 -0.25em 0 0.5em;
  position: relative;
  top: 1px;
}
/* Hide Feedbackify button on mobile */
@media (max-width: 767px) {
  div#feedbackify {
    display: none;
  }
}
#mobile-menu {
  display: none;
  visibility: hidden;
}
@media (max-width: 767px) {
  #mobile-menu {
    background: url("../images/mobile-nav.png") no-repeat scroll 0 0 transparent;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 30px;
    margin: 30px 0 0;
    width: 27px;
    visibility: visible;
  }
  .region-header {
    display: none;
  }
}
@media (min-width: 768px) {
  .region-header {
    display: block !important;
  }
  #block-menu-block-platform-menus-custom {
    float: left;
  }
  #block-menu-block-platform-menus-custom ul {
    margin: 0;
  }
  #block-menu-block-platform-menus-custom1 {
    float: right;
    width: 50%;
  }
  #block-menu-block-platform-menus-custom1 ul {
    float: right;
  }
  #block-menu-block-platform-menus-custom1 ul li {
    float: left;
  }
  #block-menu-block-platform-menus-custom1 ul li:before {
    color: #4b4b4b;
    content: "|";
    margin-right: 10px;
    padding-left: 10px;
  }
  #block-menu-block-platform-menus-custom1 ul li:first-child:before {
    content: "";
    padding-left: 0px;
    margin-right: 0px;
  }
  #block-menu-block-platform-menus-custom1 ul li a.twitter {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/twitter.png);
    background-repeat: no-repeat;
    padding-left: 40px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
  }
  #block-menu-block-platform-menus-custom1 ul li a.linkedin {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/linkedin.png);
    background-repeat: no-repeat;
    padding-left: 35px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
  }
  #block-menu-block-platform-menus-custom1 ul li a.facebook {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/facebook.png);
    background-repeat: no-repeat;
    padding-left: 35px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
  }
}
.header-separator {
  border-top: solid 1px #ccc;
  height: 0px;
  margin-top: 20px;
}
#page-header {
  font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
  position: relative;
}
#page-header a {
  color: #4b4b4b;
}
#page-header .logo {
  float: left;
}
@media (min-width: 768px) {
  #page-header .logo {
    margin-bottom: 30px;
    position: absolute;
    top: 30px;
    left: 0;
  }
}
@media (max-width: 767px) {
  #page-header .logo {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  #page-header .region-header .header-fix {
    margin-top: 30px;
  }
  #page-header .region-header .menu-block-platform_menus-3 {
    float: right;
  }
  #page-header .region-header .menu-block-platform_menus-3 .menu li {
    display: inline;
  }
  #page-header .region-header .menu-block-platform_menus-3 .menu li a {
    display: inline;
  }
  #page-header .region-header .menu-block-platform_menus-3 .menu li:before {
    color: #4b4b4b;
    content: "|";
    margin-right: 10px;
    padding-left: 10px;
  }
  #page-header .region-header .menu-block-platform_menus-3 .menu li.first:before {
    display: none;
  }
  #page-header .region-header #block-search-form {
    float: right;
    clear: right;
    width: 510px;
  }
  #page-header .region-header #block-search-form #search-block-form,
  #page-header .region-header #block-search-form #search-block-form--2 {
    background-color: #e8e6dc;
  }
  #page-header .region-header #block-search-form #search-block-form .search-query,
  #page-header .region-header #block-search-form #search-block-form--2 .search-query {
    border-radius: 0;
    background: none repeat scroll 0 0 transparent;
    border: none;
    padding-top: 7px;
    color: #000;
  }
  #page-header .region-header #block-search-form #search-block-form .btn,
  #page-header .region-header #block-search-form #search-block-form--2 .btn {
    border-radius: 0;
    background: none repeat scroll 0 0 transparent;
    border: none;
  }
  #page-header .region-header #block-search-form #search-block-form input,
  #page-header .region-header #block-search-form #search-block-form--2 input,
  #page-header .region-header #block-search-form #search-block-form button,
  #page-header .region-header #block-search-form #search-block-form--2 button {
    color: #4b4b4b;
    font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }
  #page-header .region-header #block-search-form #search-block-form .icon-search,
  #page-header .region-header #block-search-form #search-block-form--2 .icon-search {
    background-position: -48px 0;
  }
}
@media (max-width: 767px) {
  #page-header .region-header {
    background-color: #e8e6dc;
    clear: both;
    margin-bottom: 25px;
    padding: 10px;
  }
  #page-header .region-header .header-fix {
    margin-top: 20px;
  }
  #page-header .region-header .menu-block-platform_menus-3 .nav {
    margin-bottom: 0;
  }
  #page-header .region-header .menu-block-platform_menus-3 li {
    padding: 18px 0 20px 0;
    border-bottom: 2px solid #cac9c1;
  }
  #page-header .region-header .menu-block-platform_menus-3 li a {
    margin-left: 15px;
  }
  #page-header .region-header #block-menu-block-platform-menus-custom1 ul {
    padding: 0;
    margin: 0;
  }
  #page-header .region-header li {
    padding: 18px 0px 20px;
    border-bottom: 2px solid #CAC9C1;
  }
  #page-header .region-header li a.twitter {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/twitter.png);
    background-repeat: no-repeat;
    padding-left: 40px;
    line-height: 22px;
    height: 22px;
    margin-left: 15px;
  }
  #page-header .region-header li a.linkedin {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/linkedin.png);
    background-repeat: no-repeat;
    padding-left: 35px;
    line-height: 22px;
    height: 22px;
    margin-left: 15px;
  }
  #page-header .region-header li a.facebook {
    background-image: url(/sites/rsagroup.ca/themes/bootstrap_base/images/facebook.png);
    background-repeat: no-repeat;
    padding-left: 35px;
    line-height: 22px;
    height: 22px;
    margin-left: 15px;
  }
  #page-header .region-header #block-search-form {
    margin-bottom: 10px;
  }
  #page-header .region-header #block-search-form #search-block-form .search-query,
  #page-header .region-header #block-search-form #search-block-form--2 .search-query {
    border-radius: 0;
    background: #fff repeat scroll 0 0 transparent;
    border: none;
    padding-top: 7px;
    color: #000;
    height: 40px;
  }
  #page-header .region-header #block-search-form #search-block-form .btn,
  #page-header .region-header #block-search-form #search-block-form--2 .btn {
    border-radius: 0;
    background: #fff;
    border: none;
    height: 40px;
    color: #000;
  }
  #page-header .region-header #block-search-form #search-block-form .btn .icon-search,
  #page-header .region-header #block-search-form #search-block-form--2 .btn .icon-search {
    background-position: -48px 0;
  }
  #page-header .region-header #block-search-form #search-block-form input,
  #page-header .region-header #block-search-form #search-block-form--2 input,
  #page-header .region-header #block-search-form #search-block-form button,
  #page-header .region-header #block-search-form #search-block-form--2 button {
    color: #fff;
    background-color: #fff;
    font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }
}
#page-header .breadcrumb {
  background: none;
  clear: both;
  margin: 0;
  padding: 15px 0 0 0;
  font-weight: bold;
}
#page-header .breadcrumb a {
  color: #5a2d7e;
}
#page-header .breadcrumb li > .divider {
  color: #666;
}
@media (max-width: 767px) {
  body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 12px;
  }
  body .main-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}
a,
a:hover,
a:focus {
  text-decoration: underline;
  color: #5a2d7e;
}
table {
  background-color: #f3f3f3;
  width: 100%;
  margin-bottom: 18px;
}
table th {
  background-color: #e8e6dc;
  border-top: none;
  border-right: solid 16px #fff;
  border-bottom: solid 4px #fff;
  border-left: none;
  padding: 12px;
}
table th:last-child {
  border-right: none;
}
table td {
  border-top: solid 2px #fff;
  border-right: solid 16px #fff;
  border-bottom: none;
  border-left: none;
  padding: 12px;
}
table td:last-child {
  border-right: none;
}
.lnk-icon {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  padding-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e8f6';
}
.lnk-callout {
  text-decoration: none;
  display: inline-block;
  font-family: rsa_sansmedium;
}
.lnk {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 8px 18px;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #6cb1b6;
  border: 1px solid #569099;
  *border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  color: #fff;
  text-decoration: none;
  max-height: 45px;
  letter-spacing: .1em;
}
.lnk:first-child {
  *margin-left: 0;
}
.lnk:hover,
.lnk:focus {
  text-decoration: none;
  color: #fff;
  background-color: #89c1c5;
}
.lnk-primary {
  background-color: #cd0084;
  padding: 12px 22px;
  border: 1px solid #990066;
  font-size: 17.5px;
  max-height: 60px;
  text-transform: uppercase;
  letter-spacing: .05em;
}
.lnk-primary:hover,
.lnk-primary:focus {
  background-color: #df5db1;
}
.container.no-gutter {
  width: 1200px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 979px), (min-width: 980px) and (max-width: 1199px) {
  .container.no-gutter {
    width: 100%;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .row-fluid .span9.content-area {
    width: 68.35897435897436%;
    *width: 68.30578286961266%;
  }
  .row-fluid .span3.sidebar {
    margin-left: 8.564102564102564%;
    *margin-left: 8.5109110747408616%;
  }
}
.nav > li > a:hover,
.nav > li > a:focus {
  background: none;
  color: #666;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  color: #666;
}
h1 {
  color: #6cb1b6;
  font-size: 3.125em;
}
@media (max-width: 767px) {
  h1 {
    font-size: 2.64em;
  }
}
h2 {
  font-size: 1.725em;
}
h3 {
  font-size: 17.5px;
}
h4 {
  font-size: 1.14em;
}
h1 a,
h2 a,
.breadcrumb a,
.companies-header a,
.homepage-menu-sections .views-field-title a {
  color: #5a2d7e;
  text-decoration: underline;
}
h1 a:hover,
h2 a:hover,
.breadcrumb a:hover,
.companies-header a:hover,
.homepage-menu-sections .views-field-title a:hover {
  color: #7b5799;
  text-decoration: underline;
}
.btn {
  background-color: #6CB1B6;
  background-image: none;
  border: 1px solid #569099;
  box-shadow: none;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-shadow: none;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.resizable-textarea textarea {
  border-radius: 0 0 0 0;
}
.styled-select select {
  background: transparent;
  width: 100%;
  padding: 5px;
  font-size: 12px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  height: 30px;
  -webkit-appearance: none;
}
.styled-select {
  width: 100%;
  height: 30px;
  overflow: hidden;
  background: url("../images/select-arrow.png") no-repeat 98% center white;
  border: 1px solid #d8d8d8;
}
.homepageBlockTitle {
  color: #666;
  font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .homepageColumnSpan4 {
    width: 32.6667%;
    float: left;
  }
}
@media (min-width: 768px) {
  .homepageMarginSpan4 {
    margin-left: 1%;
  }
}
.homepage-menu-sections.span4 {
  padding-top: 10px;
  margin-bottom: 35px;
}
.homepage-menu-sections.span4 .links {
  margin-top: 25px;
}
.homepage-menu-sections.span4 .views-field-title {
  padding-bottom: 8px;
  margin-bottom: 24px;
  font-family: rsa_sansregular;
  font-size: 1.6em;
  line-height: 1.4em;
  color: #666;
  text-transform: uppercase;
}
.homepage-menu-sections.span4 .views-field-body h3 {
  font-size: 1.3em;
}
.homepage-menu-sections.span4 .views-field-body a,
.homepage-menu-sections.span4 .views-field-body a:hover,
.homepage-menu-sections.span4 .views-field-body a:visited {
  text-decoration: none;
  display: inline-block;
  font-family: rsa_sansmedium;
  margin-bottom: 8px;
  display: table-row;
}
.homepage-menu-sections.span4 .views-field-body a:before {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  padding-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e8f6';
  display: table-cell;
}
.homepage-menu-sections.item1 {
  margin-left: 0;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .homepage-menu-sections.item1 {
    padding-right: 53.333px;
  }
}
.homepage-menu-sections.item1 .views-field-title {
  border-bottom: 3px solid #cd0084;
}
.homepage-menu-sections.item1 .views-field-body a,
.homepage-menu-sections.item1 .views-field-body i,
.homepage-menu-sections.item1 .views-field-body h3 {
  color: #cd0084;
}
.homepage-menu-sections.item1 .views-field-body h3 {
  font-family: rsa_sansregular;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .homepage-menu-sections.item2 {
    padding-right: 26.666px;
    padding-left: 26.666px;
  }
}
@media (min-width: 768px) {
  .homepage-menu-sections.item2 {
    margin-left: 1%;
  }
}
.homepage-menu-sections.item2 .views-field-title {
  border-bottom: 3px solid #83b0b5;
}
.homepage-menu-sections.item2 .views-field-body a,
.homepage-menu-sections.item2 .views-field-body i,
.homepage-menu-sections.item2 .views-field-body h3 {
  color: #83b0b5;
}
.homepage-menu-sections.item2 .views-field-body h3 {
  font-family: rsa_sansregular;
}
.homepage-menu-sections.item2 .separator {
  margin-top: 20px;
  border-top: solid 1px #83b0b5;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .homepage-menu-sections.item3 {
    padding-left: 53.333px;
  }
}
.homepage-menu-sections.item3 .views-field-title {
  border-bottom: 3px solid #5a2d7e;
}
.homepage-menu-sections.item3 .views-field-body a,
.homepage-menu-sections.item3 .views-field-body i,
.homepage-menu-sections.item3 .views-field-body h3 {
  color: #5a2d7e;
}
.homepage-menu-sections.item3 .views-field-body h3 {
  font-family: rsa_sansregular;
}
.homepage-companies.span12 {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .homepage-companies.span12 {
    display: none;
  }
}
.homepage-companies.span12 .companies-header {
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: 979px) {
  .homepage-companies.span12 .companies-header {
    display: none;
  }
}
.homepage-companies.span12 > div {
  flex-basis: 21.2%;
  font-family: rsa_sansmedium;
  font-size: 1rem;
  background: #e8e6dc;
  margin-right: 1.5%;
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
}
@media (min-width: 768px) and (max-width: 979px) {
  .homepage-companies.span12 > div {
    flex-basis: 27.2%;
  }
}
.homepage-companies.span12 > div:last-child {
  margin-right: 0;
}
.homepage-companies.span12 .item1,
.homepage-companies.span12 .item2,
.homepage-companies.span12 .item3 {
  position: relative;
}
.homepage-companies.span12 .item1 div,
.homepage-companies.span12 .item2 div,
.homepage-companies.span12 .item3 div {
  vertical-align: middle;
}
@media (min-width: 1200px) {
  .homepage-companies.span12 .item1 div,
  .homepage-companies.span12 .item2 div,
  .homepage-companies.span12 .item3 div {
    display: inline;
  }
}
@media (min-width: 1200px) {
  .homepage-companies.span12 .item1 .views-field-field-logo-1,
  .homepage-companies.span12 .item2 .views-field-field-logo-1,
  .homepage-companies.span12 .item3 .views-field-field-logo-1 {
    position: absolute;
    top: 2px;
  }
}
.homepage-companies.span12 .item1 .views-field-field-logo-1 img,
.homepage-companies.span12 .item2 .views-field-field-logo-1 img,
.homepage-companies.span12 .item3 .views-field-field-logo-1 img {
  height: 22px;
  width: auto;
}
.homepage-companies.span12 .item1 .views-field-field-link,
.homepage-companies.span12 .item2 .views-field-field-link,
.homepage-companies.span12 .item3 .views-field-field-link {
  margin-left: 0;
  margin-top: 5px;
}
@media (min-width: 1200px) {
  .homepage-companies.span12 .item1 .views-field-field-link,
  .homepage-companies.span12 .item2 .views-field-field-link,
  .homepage-companies.span12 .item3 .views-field-field-link {
    margin-left: 55px;
  }
}
.homepage-companies.span12 .item1 .views-field-field-link a,
.homepage-companies.span12 .item2 .views-field-field-link a,
.homepage-companies.span12 .item3 .views-field-field-link a {
  text-decoration: none;
}
.homepage-companies.span12 .item1 .views-field-field-link a:after,
.homepage-companies.span12 .item2 .views-field-field-link a:after,
.homepage-companies.span12 .item3 .views-field-field-link a:after {
  font-family: rsa-icons;
  font-style: normal;
  font-weight: 400;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  padding-left: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e8f6";
}
.homepage-companies.span12 .item1 .views-field-field-logo-1 img {
  height: 26px;
}
.homepage-companies.span12 .item1 .views-field-field-link {
  margin-top: 0;
}
@media (min-width: 1200px) {
  .homepage-companies.span12 .item1 .views-field-field-link {
    margin-left: 102px;
  }
}
#page-header .region-header > .homepage-companies.span12 {
  display: none;
}
@media (max-width: 767px) {
  #page-header .region-header > .homepage-companies.span12 {
    display: block;
  }
}
#page-header .region-header > .homepage-companies.span12 .companies-header {
  margin-bottom: 3px;
  text-transform: uppercase;
}
#page-header .region-header > .homepage-companies.span12 > div {
  font-size: .9rem;
  padding-top: 10px;
  padding-bottom: 8px;
  text-transform: none;
}
#page-header .region-header > .homepage-companies.span12 .item1 div,
#page-header .region-header > .homepage-companies.span12 .item2 div,
#page-header .region-header > .homepage-companies.span12 .item3 div {
  display: inline;
}
#page-header .region-header > .homepage-companies.span12 .item1 img,
#page-header .region-header > .homepage-companies.span12 .item2 img,
#page-header .region-header > .homepage-companies.span12 .item3 img {
  margin-right: 2%;
}
#page-header .region-header > .homepage-companies.span12 .item1 a,
#page-header .region-header > .homepage-companies.span12 .item2 a,
#page-header .region-header > .homepage-companies.span12 .item3 a {
  color: #5a2d7e;
}
#page-header .region-header > .homepage-companies.span12 .item2 img,
#page-header .region-header > .homepage-companies.span12 .item3 img {
  padding-bottom: 6px;
}
.badges-container-homepage .badges {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .badges-container-homepage .badges {
    margin-top: 40px;
  }
}
.badges-container-homepage .badges .badges-header {
  padding-bottom: 8px;
  margin-bottom: 20px;
  font-family: rsa_sansregular;
  font-size: 1.6em;
  color: #666;
  text-transform: uppercase;
  border-bottom: solid 3px #5a2d7e;
}
.badges-container-homepage .badges .span4 {
  position: relative;
}
@media (min-width: 768px) {
  .badges-container-homepage .badges .span4 {
    width: 32.6667%;
    float: left;
  }
}
@media (max-width: 767px) {
  .badges-container-homepage .badges .span4 {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .badges-container-homepage .badges .span4 {
    margin-bottom: 30px;
  }
}
.badges-container-homepage .badges .span4 h2.block-title {
  font-size: 1.3em;
  margin: 20px 0px;
  position: relative;
  z-index: 100;
  max-width: 66%;
}
@media (min-width: 768px) {
  .badges-container-homepage .badges .row-fluid [class*="item"] {
    margin-left: 1%;
  }
}
.badges-container-homepage .badges .row-fluid [class*="item"]:first-child {
  margin-left: 0;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .badges-container-homepage .badges .item1 {
    padding-right: 53.333px;
  }
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .badges-container-homepage .badges .item2 {
    padding-right: 26.666px;
    padding-left: 26.666px;
  }
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  .badges-container-homepage .badges .item3 {
    padding-left: 53.333px;
  }
}
.badges-container-homepage .badges .badge-link {
  margin-top: 12px;
  text-align: right;
}
.badges-container-homepage .badges .badge-link a,
.badges-container-homepage .badges .badge-link a:hover,
.badges-container-homepage .badges .badge-link a:visited {
  text-decoration: none;
  display: inline-block;
  font-family: rsa_sansmedium;
  color: #5a2d7e;
  display: table-row;
}
.badges-container-homepage .badges .badge-link a:before {
  font-family: "rsa-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  padding-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e8f6';
  display: table-cell;
}
.badges-container-homepage .badges .badge-image {
  width: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .badges-container-homepage .badges .badge-image img {
    width: auto;
  }
}
.badges-container-homepage .badges .badge-spacer,
.badges-container-homepage .badges .badge-spacer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  z-index: 10;
}
@media (max-width: 767px) {
  .badges-container-homepage .badges .badge-spacer,
  .badges-container-homepage .badges .badge-spacer img {
    height: 140px;
  }
}
.badges-container .badges .span4 {
  padding: 5px 0 0 14px;
  height: 190px;
  background-color: #e8e6dc;
  position: relative;
}
@media (min-width: 768px) {
  .badges-container .badges .span4 {
    width: 32.6667%;
    float: left;
  }
}
@media (max-width: 767px) {
  .badges-container .badges .span4 {
    height: 140px;
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .badges-container .badges .span4 {
    margin-bottom: 30px;
  }
}
.badges-container .badges .span4 h2.block-title {
  color: #666;
  font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 0;
  position: relative;
  z-index: 100;
  max-width: 66%;
}
@media (min-width: 768px) {
  .badges-container .badges .row-fluid [class*="item"] {
    margin-left: 1%;
  }
}
.badges-container .badges .row-fluid [class*="item"]:first-child {
  margin-left: 0;
}
.badges-container .badges .badge-link {
  position: relative;
  z-index: 10;
}
.badges-container .badges .badge-image {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .badges-container .badges .badge-image img {
    max-height: 140px;
    width: auto;
  }
}
.badges-container .badges .badge-spacer,
.badges-container .badges .badge-spacer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  z-index: 10;
}
@media (max-width: 767px) {
  .badges-container .badges .badge-spacer,
  .badges-container .badges .badge-spacer img {
    height: 140px;
  }
}
.content-area {
  border-top: solid 5px #e8e6dc;
}
.content-area h1.page-header {
  border: none;
  margin: 0;
}
@media (max-width: 767px) {
  .content-area h1.page-header {
    margin-top: 20px;
  }
}
.content-area .btn {
  margin: 10px 0;
}
@media (min-width: 768px) {
  .content-area {
    padding-top: 40px;
  }
}
.content-area .field-name-field-preamble {
  font-size: 1.14em;
  line-height: 1.41em;
  margin-bottom: 10px;
}
.content-area .nav li {
  font-family: 'Gill Sans W01 Book', "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 1.14em;
  line-height: 1.71em;
}
.content-area .nav li a:hover,
.content-area .nav li a.active {
  color: #7b5799;
}
.front .content-area {
  border-top: 0;
}
.sidebar .menu {
  border-top: solid 5px #cd0084;
  font-size: 1.285em;
  line-height: 1.715em;
}
.sidebar .nav li {
  padding: 18px 0 20px 0;
  border-bottom: 2px solid #cac9c1;
}
.sidebar .nav li .last {
  border-bottom: none;
}
.sidebar .nav li ul li {
  border-bottom: none;
  font-size: .9em;
  padding: 18px 0 0 0;
}
.sidebar .nav li ul li .active {
  text-decoration: none;
}
.sidebar .nav li a {
  color: #5a2d7e;
}
.sidebar .nav li a:hover,
.sidebar .nav li a:focus,
.sidebar .nav li a.active-trail {
  color: #666;
}
@media (max-width: 767px) {
  .sidebar {
    margin-top: 40px;
  }
}
.sidebar .view-display-id-block {
  margin-top: 40px;
  font-size: 1.285em;
  line-height: 1.715em;
}
.sidebar .view-display-id-block .views-field-title {
  padding: 18px 0 20px 0;
}
.sidebar .view-display-id-block .views-field-field-link-x {
  border-top: solid 5px #6cb1b6;
}
.sidebar .view-display-id-block ul {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
}
.sidebar .view-display-id-block ul li {
  padding: 18px 0 20px 0;
  border-bottom: 2px solid #cac9c1;
}
.sidebar .view-display-id-block ul li .last {
  border-bottom: none;
}
.sidebar .view-display-id-block ul li a {
  color: #5a2d7e;
}
#homepage-banner {
  position: relative;
  text-align: center;
  z-index: 0;
}
@media (min-width: 768px) {
  #homepage-banner {
    margin-bottom: 20px;
  }
}
#homepage-banner .homepage-banner-caption {
  position: absolute;
  padding: 20px 80px;
  background-color: #fff;
  width: 50%;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  -webkit-box-shadow: 0px 12px 6px -6px #ccc;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 12px 6px -6px #ccc;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 12px 6px -6px #ccc;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
@media (min-width: 768px) and (max-width: 979px) {
  #homepage-banner .homepage-banner-caption {
    width: 60%;
  }
}
@media (max-width: 767px) {
  #homepage-banner .homepage-banner-caption {
    padding: 0px;
    margin: 20px;
    width: auto;
    bottom: 0px;
    position: relative;
    -webkit-box-shadow: none;
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none;
    /* Firefox 3.5 - 3.6 */
    box-shadow: none;
    /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
}
#homepage-banner .homepage-banner-title {
  position: relative;
  top: 0;
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0;
  z-index: 100;
}
#homepage-banner .homepage-banner-title h1 {
  text-transform: none;
  color: #4b4b4b;
  font-size: 3em;
  padding: 0;
  margin: 0;
  line-height: 64px;
  font-family: rsa_sansmedium;
}
@media (min-width: 768px) and (max-width: 979px), (max-width: 767px) {
  #homepage-banner .homepage-banner-title h1 {
    font-size: 2.6em;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  #homepage-banner .homepage-banner-title h1 {
    font-size: 2.2em;
    line-height: 32px;
  }
}
#homepage-banner .homepage-banner-subtext {
  position: relative;
  color: #4b4b4b;
  font-size: 1.4em;
  line-height: 28px;
}
@media (min-width: 768px) and (max-width: 979px) {
  #homepage-banner .homepage-banner-subtext {
    font-size: 1.4em;
    line-height: 24px;
  }
}
#homepage-banner .homepage-banner-image {
  z-index: 2;
  top: 0;
  margin-bottom: 20px;
}
#homepage-banner .homepage-banner-image img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 979px), (max-width: 767px) {
  #homepage-banner .homepage-banner-image {
    min-height: 150px;
  }
  #homepage-banner .homepage-banner-image img {
    min-height: 150px;
  }
}
@media (min-width: 768px) {
  #homepage-banner .homepage-banner-image {
    margin: 35px 45px;
  }
}
#homepage-banner .banner-ribbon-left {
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 35%;
}
#homepage-banner .banner-ribbon-right {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  max-width: 35%;
}
@media (max-width: 767px) {
  #homepage-banner .banner-ribbon-right {
    display: none;
  }
}
.page-search #page-header {
  margin-bottom: 0;
}
.page-search .content-area {
  border-top: 0;
  padding-top: 0;
}
.page-search .content-area h2 {
  border-bottom: solid 5px #e8e6dc;
  padding-bottom: 10px;
}
.page-search .search-results .search-result {
  margin-top: 30px;
}
.page-search .search-results h3.title {
  font-size: 1.725em;
  text-transform: uppercase;
}
.page-search .search-results h3.title a {
  color: #5a2d7e;
}
.page-search .search-results h3.title a:hover {
  color: #7b5799;
}
.triptych {
  clear: both;
  position: relative;
  z-index: 0;
}
@media (min-width: 768px) {
  .triptych .ptux,
  .triptych .contact-icon {
    float: left;
    margin-right: 9.5%;
    width: 27%;
  }
  .triptych .ptux-3,
  .triptych .cicon-webform {
    margin-right: 0;
  }
  .triptych .contact-icon {
    background: url(../images/contact-icons.png) no-repeat;
    height: 45px;
  }
  .triptych .contact-icon.cicon-phone {
    background-position: 0 0;
  }
  .triptych .contact-icon.cicon-locations {
    background-position: 0 -50px;
  }
  .triptych .contact-icon.cicon-webform {
    background-position: 0 -100px;
  }
}
@media (max-width: 767px) {
  .triptych .contact-columns {
    clear: both;
  }
  .triptych .contact-icon {
    background: url(../images/contact-icons.png) no-repeat;
    float: left;
    height: 60px;
    width: 33%;
    margin-bottom: 20px;
  }
  .triptych .contact-icon:hover {
    cursor: pointer;
  }
  .triptych .contact-icon.cicon-phone {
    background-position: 0 -160px;
  }
  .triptych .contact-icon.cicon-locations {
    background-position: center -240px;
  }
  .triptych .contact-icon.cicon-webform {
    background-position: right -320px;
  }
  .triptych .ptux {
    width: 100%;
  }
  .triptych .ptux-1 {
    display: block;
  }
  .triptych .ptux-1:before {
    border-bottom: 15px solid #E8E6DC;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    height: 0;
    left: 18px;
    position: absolute;
    top: 65px;
    width: 0;
  }
  .triptych .ptux-2 {
    display: none;
  }
  .triptych .ptux-2:before {
    border-bottom: 15px solid #E8E6DC;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    height: 0;
    left: 44%;
    position: absolute;
    top: 65px;
    width: 0;
  }
  .triptych .ptux-3 {
    display: none;
  }
  .triptych .ptux-3:before {
    border-bottom: 15px solid #E8E6DC;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    height: 0;
    right: 26px;
    position: absolute;
    top: 65px;
    width: 0;
  }
  .triptych section {
    background-color: #e8e6dc;
    padding: 20px 10px;
  }
}
.triptych .ui-accordion {
  width: 100%;
}
.triptych .ui-accordion .ui-accordion-header {
  cursor: pointer;
  margin-top: 1px;
  position: relative;
}
.triptych .ui-accordion .ui-accordion-li-fix {
  display: inline;
}
.triptych .ui-accordion .ui-accordion-header-active {
  border-bottom: 0 none !important;
}
.triptych .ui-accordion .ui-accordion-header a {
  display: block;
  font-size: 1em;
  padding: 0.75em 0.5em 0.75em 0.7em;
}
.triptych .ui-accordion-icons .ui-accordion-header a {
  padding-left: 0;
}
.triptych .ui-accordion .ui-accordion-header .ui-icon {
  display: none;
}
.triptych .ui-accordion .ui-accordion-content {
  border-top: 0 none;
  display: none;
  margin-bottom: 2px;
  margin-top: -2px;
  overflow: auto;
  padding: 0 15px;
  position: relative;
  top: 1px;
}
.triptych .ui-accordion .ui-accordion-content-active {
  display: block;
}
.triptych .ui-corner-all {
  border-radius: 0;
}
.triptych .ui-helper-reset {
  font-size: 0.925em;
  line-height: 1.3;
  list-style: none outside none;
}
.triptych .ui-state-active,
.triptych .ui-widget-content .ui-state-active,
.triptych .ui-widget-header .ui-state-active {
  border: 0;
  border-top: 1px solid #e8e6dc;
}
.triptych .ui-widget-content {
  background: none;
  border: 0;
}
@media (min-width: 768px) {
  .triptych .ui-state-default,
  .triptych .ui-widget-content .ui-state-default,
  .triptych .ui-widget-header .ui-state-default,
  .triptych .ui-state-hover,
  .triptych .ui-widget-content .ui-state-hover,
  .triptych .ui-widget-header .ui-state-hover,
  .triptych .ui-state-focus,
  .triptych .ui-widget-content .ui-state-focus,
  .triptych .ui-widget-header .ui-state-focus {
    border: 0;
    background: none;
    border-top: 2px solid #e8e6dc;
  }
}
@media (max-width: 767px) {
  .triptych .ui-state-default,
  .triptych .ui-widget-content .ui-state-default,
  .triptych .ui-widget-header .ui-state-default,
  .triptych .ui-state-hover,
  .triptych .ui-widget-content .ui-state-hover,
  .triptych .ui-widget-header .ui-state-hover,
  .triptych .ui-state-focus,
  .triptych .ui-widget-content .ui-state-focus,
  .triptych .ui-widget-header .ui-state-focus {
    border: 0;
    background: none;
    border-top: 2px solid #cac9c1;
  }
}
.triptych .views-row-1 .ui-state-active,
.triptych .views-row-1 .ui-state-default {
  border: 0;
}
.accordion {
  margin-bottom: 22px;
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
#gs_accordion .accordion-group {
  border-radius: 0px;
  border: none;
  border-bottom: 3px solid #000;
}
#gs_accordion .accordion-inner {
  border: none;
}
.gs_btn_icon {
  width: 10%;
  vertical-align: middle;
  float: right;
}
#gs_accordion .gs_icon {
  width: 6%;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#gs_accordion .accordion-heading a {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  /* color: #6f2c91;
    font-weight: bold;
    font-family: 'RSA Sans Medium';
    font-size: 14px;*/
  width: 80%;
}
#gs_accordion .accordion-heading a:hover {
  color: #00B5D5;
}
#gs_gradient {
  background-image: url('/sites/rsagroup.ca/files/gs_gradient.png');
  background-size: cover;
  color: white;
  font-size: 10px;
  line-height: 12px;
}
#gs_accordion .gs_indicator {
  float: right;
  /*padding-top: 15px;*/
}
#gs_accordion .gs_indicator img {
  transform: translateY(1.4em);
}
@media (max-width: 766px) {
  #gs_accordion .gs_indicator img {
    transform: translateY(0.5em);
  }
}
#gs_accordion .accordion-inner {
  padding-left: 0px;
  padding-right: 0px;
}
.gs_mobile_icon {
  float: left;
  vertical-align: middle;
  margin-right: 10px;
}
#gs_accordion .gs_mobile_icon {
  width: 10%;
}
.make-claim-pg .hero {
  padding-bottom: 30px;
  border-bottom: 3px solid #000;
}
.make-claim-pg .hero h1 {
  padding-top: 0.6em;
}
.make-claim-pg .hero .sub-section {
  margin-bottom: 30px;
}
.make-claim-pg .hero .sub-section .cta {
  padding: 28px 0;
  text-align: center;
}
.make-claim-pg .hero .sub-section .cta img {
  height: 110px;
}
.make-claim-pg .hero .sub-section span.text-underline {
  text-decoration: underline;
}
.make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner {
  padding-bottom: 20px;
}
.make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info {
  margin-bottom: 10px;
}
.make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info .division {
  display: inline-block;
  vertical-align: top;
  width: 45%;
}
@media (max-width: 766px) {
  .make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info .division {
    width: 55%;
  }
}
.make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info .contact-details {
  display: inline-block;
  width: 50%;
}
@media (max-width: 766px) {
  .make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info .contact-details {
    width: 40%;
  }
}
.make-claim-pg .accordion .accordion-group .accordion-body .accordion-inner .contact-info .contact-details .br-big {
  line-height: 2em;
}
.make-claim-pg .phone-number {
  white-space: nowrap;
}
.not-front .span12 {
  margin-left: 0;
}
.not-front .span12 #block-views-badge-block {
  margin-top: 20px;
}
.webform-client-form .captcha {
  margin-top: 25px;
}
a.chat-button {
  font-family: rsa_sansregular;
  font-size: 12px;
  background: #E30989;
}
a.chat-button::before {
  font-family: "chat-rsa";
  content: '\e900';
  font-size: 20px;
  text-align: left;
}
@media (min-width: 768px) {
  a.chat-button {
    width: 40px;
    overflow: visible;
    line-height: 16px;
  }
  a.chat-button::before {
    font-size: 30px;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and screen and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  a.chat-button {
    padding-top: 12px;
  }
}
@media (min-width: 768px) and screen and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  a.chat-button::before {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  a.chat-button {
    padding-top: 0px;
    padding-bottom: 5px;
  }
  a.chat-button::before {
    position: relative;
    top: 3px;
    margin-right: 5px;
  }
}
a.chat-button:hover {
  text-decoration: none;
}
/* Turn off Feedbackify tab on the right rail */
#feedbackify .fby-tab-r {
  display: none;
}
/* Feedbackify footer styling */
.feedback-bar {
  position: relative;
  top: 45px;
  background-color: #E3E3E3;
  padding: 1.5em;
  text-align: center;
}
.feedback-bar a {
  text-decoration: underline;
  font-weight: bold;
}
.feedback-bar a:hover {
  text-decoration: none;
  /* color: @color-link-hover; */
}
.footer {
  background-color: #333;
  text-align: center;
  width: 100%;
}
.footer a {
  color: #fff;
  font-weight: bold;
}
.footer .nav > li > a:hover {
  color: #fff;
}
@media (min-width: 768px) {
  .footer .menu li {
    display: inline;
  }
  .footer .menu li:after {
    color: #fff;
    content: "|";
    margin-right: 6px;
    padding-left: 10px;
  }
  .footer .menu li.last:after {
    display: none;
  }
  .footer .menu li a {
    display: inline;
    /* need to remove block */
  }
}
.footer #block-menu-menu-footer-menu {
  /* Add link height for mobile view */
}
@media (max-width: 767px) {
  .footer #block-menu-menu-footer-menu li {
    padding-top: 25px;
  }
  .footer #block-menu-menu-footer-menu li.first {
    padding-top: 0;
  }
}
.footer #block-menu-menu-social-links {
  margin-top: 40px;
}
.footer #block-menu-menu-social-links .menu li {
  display: inline;
}
.footer #block-menu-menu-social-links .menu li a {
  height: 25px;
  display: inline-block;
  padding-top: 5px;
}
.footer #block-menu-menu-social-links .menu li:after {
  content: "";
  margin-right: 6px;
  padding-left: 10px;
}
.footer #block-menu-menu-social-links .twitter {
  background-image: url("../images/twitter.png");
  background-repeat: no-repeat;
  padding-left: 40px;
}
.footer #block-menu-menu-social-links .linkedin {
  background-image: url("../images/linkedin.png");
  background-repeat: no-repeat;
  padding-left: 35px;
}
.footer #block-menu-menu-social-links .facebook {
  background-image: url("../images/facebook.png");
  background-repeat: no-repeat;
  padding-left: 35px;
}
.footer #boxes-box-copyright_note {
  color: #999;
  margin-top: 40px;
}
.node-type-news-release .content-area {
  /* max-width: 700px; */
}
.node-type-news-release .content-area h1.page-header {
  font-size: 38px;
  line-height: 46px;
}
.node-type-news-release .content-area .field-name-field-publication-date {
  font-family: rsa_sansmedium;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E8E6DC;
}
.node-type-news-release .content-area .field-name-field-preamble {
  font-family: rsa_sansregular;
  color: #666;
  font-size: 28px;
  line-height: 43px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.node-type-news-release .content-area .field-name-body {
  font-size: 16px;
  line-height: 28px;
  font-family: rsa_sansregular;
  color: #2E2E2E;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E6DC;
  margin-bottom: 20px;
}
.node-type-news-release .content-area .field-name-body .rsa-news-quote {
  border-top: 6px solid #FF49A4;
  margin-left: 5%;
  margin-top: 30px;
  padding: 30px 10px 20px 40px;
}
.node-type-news-release .content-area .field-name-body .rsa-news-quote > p {
  font-family: "RSA Sans Light", Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 43px;
  padding-bottom: 20px;
}
.node-type-news-release .content-area .field-name-body .rsa-news-quote .rsa-news-quote-author {
  text-align: left;
  font-family: "RSA Sans Medium", Arial, Helvetica, sans-serif;
}
.node-type-news-release .content-area .field-name-body .rsa-news-quote .rsa-news-quote-author-title {
  text-align: left;
}
.node-type-news-release .content-area .field-name-field-media-enquiries {
  margin-top: 20px;
}
.node-type-news-release .content-area .field-name-field-media-enquiries .field-label {
  font-size: 28px;
  line-height: 43px;
  color: black;
}
.node-type-news-release .content-area .field-name-field-media-enquiries > .field-items > .field-item {
  display: inline-block;
  width: 50%;
}
.node-type-news-release .content-area .field-name-field-media-enquiries .field-item {
  font-size: 16px;
  line-height: 28px;
  color: #2e2e2e;
}
.node-type-news-release .content-area .field-name-field-media-enquiries .field-item .field-collection-view {
  border-bottom: none;
}
.node-type-news-release .content-area .field-name-field-media-enquiries .field-name-field-contact-name {
  font-family: "RSA Sans Medium", Arial, Helvetica, sans-serif;
}
.view-news-and-press-releases {
  margin-top: 40px;
}
.view-news-and-press-releases .views-row {
  border-top: 1px solid #E8E6DC;
}
.view-news-and-press-releases .views-row.views-row-last {
  border-bottom: 1px solid #E8E6DC;
}
.view-news-and-press-releases .views-row .views-field {
  display: inline-block;
  font-size: 16px;
  line-height: 60px;
}
.view-news-and-press-releases .views-row .views-field a {
  text-decoration: none;
}
.view-news-and-press-releases .views-row .views-field a:hover {
  color: #7b5799;
}
.view-news-and-press-releases .views-row .views-field.views-field-field-publication-date {
  width: 100px;
  margin-right: 30px;
}
